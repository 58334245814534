<script setup>
import { useStore } from 'vuex'
import { useVuex } from '@vueblocks/vue-use-vuex'
import { useRoute } from 'vue-router'
import { ref, onMounted, watch, computed } from 'vue'
import ItemsTable from '@/components/ItemsTable.vue'
import { LoadingIndicator, Pagination } from '@/components/creator'
import _sortBy from 'lodash-es/sortBy'
import creatorItemViews from '@/helpers/creatorItemViews'
import ItemsCards from '@/components/ItemsCards.vue'

const state = ref({
  requestInProgress: false,
  isLoading: false,
  isUpdating: false,
  isDeleting: false,
})

const props = defineProps(['viewModeSwitcher'])

const store = useStore()
const { useActions } = useVuex(null, store)
const { apiSpacesList, apiSpaceTemplatesList } = useActions([
  'apiSpacesList',
  'apiSpaceTemplatesList',
])

const route = useRoute()

const pageNumber = ref(0)
const pageSize = 5
const pagesCount = ref(0)

watch(() => route.query.search, async (newSearchQuery, oldSearchQuery) => {
  if (newSearchQuery !== oldSearchQuery) {
    pageNumber.value = 1
    await loadWorldsList()
  }
})

watch(() => pageNumber.value, async () => {
  await loadWorldsList()
})

const dataRaw = ref([])
const data = computed(() => {
  let data = dataRaw.value.slice()

  // if (route.query.search) {
  //   data = searchData(data, ['name'], route.query.search)
  // }
  if (route.query.sort === 'name' || !route.query.sort) {
    data = _sortBy(data, (item) => item.name?.toLowerCase())
  } else if (route.query.sort === 'updated') {
    data = _sortBy(data, (item) => item.updated_at)
  } else if (route.query.sort === 'created') {
    data = _sortBy(data, (item) => item.author?.full_name?.toLowerCase())
  } else if (route.query.sort === 'assigned') {
    data = _sortBy(data, (item) => '')
  }

  if (route.query.order === 'desc') data.reverse()
  return data
})

const cardItems = computed(() => {
  const _items = []
  for (const item of data.value) {
    const previewSrc = item.glbmodel
      ? `${process.env.VUE_APP_CONTENT_BASE_URL}/${item.glbmodel}`
      : ''
    const previewPoster = item.thumbnail
      ? `${process.env.VUE_APP_CONTENT_BASE_URL}/${item.thumbnail}`
      : ''

    _items.push({
      id: item.id,
      title: item.name,
      routePath: 'WorldsItem',
      routeParams: { id: item.id },
      link: undefined,
      previewPoster: previewPoster,
      previewSrc: previewSrc,
      label: undefined,
    })
  }
  return _items
})

async function loadWorldsList() {
  try {
    state.value.requestInProgress = true
    state.value.isLoading = true

    const searchQuery = route.query.search || ''
    const [spacesResult, templatesResult] = await Promise.all([
      apiSpacesList({ page: pageNumber.value, search: searchQuery, pageSize }),
      apiSpaceTemplatesList({ page: pageNumber.value, pageSize }),
    ])

    let spaces = spacesResult.content
    const templates = templatesResult.content
    pagesCount.value = spacesResult.pagination
      ? Math.ceil(spacesResult.pagination.total / pageSize)
      : undefined

    // filter out wrong spaces without existing template
    spaces = spaces.filter(
      (x) => templates.find((t) => t.id === x.template.id) !== undefined
    )

    for (const key in spaces) {
      const template = templates.filter(
        (item) => item.id === spaces[key].template.id
      )[0]
      spaces[key].glbmodel = template.glbmodel
      spaces[key].thumbnail = template.thumbnail
      spaces[key].author_fullname = spaces[key].author.full_name
    }

    dataRaw.value = spaces
  } catch (error) {
    console.error(error)
  } finally {
    state.value.requestInProgress = false
    state.value.isLoading = false
  }
}

onMounted(async () => {
  pageNumber.value = 1
})
</script>

<template>
  <template v-if="props.viewModeSwitcher?.isSelected(creatorItemViews.cards)">
    <LoadingIndicator :state="state" all />
    <ItemsCards
        class="ar-items-cards"
        :items="cardItems"
        cardWidth="346px"
        cardHeight="246px"
        :createButton="{ title: 'Create new World', name: 'WorldsNew' }"
      />
    <Teleport to="#footer">
        <Pagination
          :length="pagesCount"
          v-model="pageNumber"
          v-if="pagesCount > 1"
        />
    </Teleport>

  </template>
  <template v-if="props.viewModeSwitcher?.isSelected(creatorItemViews.table)">
    <LoadingIndicator loading :state="state" />
    <ItemsTable
      :items="data"
      :columns="[
        {
          sortKey: 'name',
          title: 'Name',
          field: 'name',
          type: 'link',
          options: { routePath: 'WorldsItem' },
        },
        {
          sortKey: 'updated_at',
          title: 'Last updated',
          field: 'updated_at',
          type: 'timestamp',
        },
        {
          sortKey: 'author_fullname',
          title: 'Created by',
          field: 'author_fullname',
        },
        { sortKey: 'assigned', title: 'Assigned', field: 'assigned' },
      ]"
    />
  </template>
</template>

<style scoped lang="scss">
</style>
