<script setup>
import { ref, computed } from 'vue'

import {
  Tooltip,
} from '@/components/creator'

const toolTip = ref()

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  hint: {
    type: String,
  },
  value: {
    type: Number,
    required: true,
  },
  valueType: {
    type: String,
    default: 'number',
  },
  changePercent: {
    type: Number,
    required: true,
  },
})

function headerHintHovered(hovered, target) {
  if (hovered) {
    const rect = target.getBoundingClientRect()

    const toolTipLeft = window.pageXOffset + rect.left + rect.width / 2 - 1
    const toolTipTop = window.pageYOffset + rect.top + rect.height + 5
    toolTip.value.show(toolTipLeft, toolTipTop)
  } else {
    toolTip.value.hide()
  }
}

const formattedValue = computed(function () {
  if (props.value === null || props.value === undefined || isNaN(props.value)) {
    return '-'
  }
  switch (props.valueType) {
    case 'number':
      if (props.value >= 1000000) {
        return `${(props.value / 1000000).toFixed(1)}M`
      } else if (props.value >= 1000) {
        return `${(props.value / 1000).toFixed(1)}K`
      } else {
        return props.value
      }
    case 'time':
      if (props.value >= 3600) {
        const hours = Math.floor(props.value / 3600)
        const minutes = Math.floor((props.value % 3600) / 60)

        if (minutes) {
          return `${hours}h ${minutes}m`
        } else {
          return `${hours}h`
        }
      } else if (props.value >= 60) {
        const minutes = Math.floor(props.value / 60)
        const seconds = props.value % 60

        if (seconds) {
          return `${minutes}m ${seconds}s`
        } else {
          return `${minutes}m`
        }
      } else {
        return `${props.value}s`
      }
    default:
      return props.value
  }
})

</script>

<template>
  <div class="metric-index__wrapper">
    <div class="header">
      <p class="header__title">{{ props.title }}</p>
      <v-icon
        :size="18"
        class="header-hint__icon"
        @mouseover="$event => headerHintHovered(true, $event.target)"
        @mouseleave="$event => headerHintHovered(false, $event.target)"
      >
        mdi-information-outline
      </v-icon>
    </div>

    <div class="content">
      <p class="value">{{ formattedValue }}</p>

      <div
        class="change-percent__wrapper"
        v-if="props.changePercent !== null && props.changePercent >= 0"
        :class="{ 'change-percent--positive': props.changePercent >= 0, 'change-percent--negative': props.changePercent < 0 }"
      >
        <v-icon :size="26" v-if="props.changePercent > 0">mdi-chevron-up</v-icon>
        <v-icon :size="26" v-if="props.changePercent < 0">mdi-chevron-down</v-icon>
        <span class="change-percent__value">{{ Math.abs(props.changePercent) }}%</span>
      </div>
    </div>

    <Tooltip ref="toolTip" v-if="props.hint" maxWidth="250px">
      <div class="max-width-tooltip__content">{{ props.hint }}</div>
    </Tooltip>
  </div>
</template>

<style lang="scss" scoped>
.metric-index__wrapper {
  width: 226px;
  background: #091520;
  border: 1px solid #D2D9E7;
  border-radius: 7px;
  padding: 4px 14px 3px;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.header__title {
  font: 500 12px 'Poppins';
  color: #ACC2D2;
}

.header__icon {
  color: #ACC2D2;

  &:hover {
    color: #00D7FF;
  }
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.value {
  font: 600 22px 'Poppins';
  color: #FFFFFF;
}

.change-percent__wrapper {
}

.change-percent__value {
  font: 400 12px 'Poppins';
}

.change-percent--positive {
  color: #2AD746;
}

.change-percent--negative {
  color: #F14953;
}
</style>
