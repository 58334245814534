<script setup>
import { computed, ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useVuex } from '@vueblocks/vue-use-vuex'
import CreateProfileDialog from '@/components/creator/CreateProfileDialog.vue'
import SelectProfileDialog from '@/components/creator/SelectProfileDialog.vue'
import { PopupMenu } from '@/components/creator'
import { UserRoles } from '@/helpers/api/models'

const store = useStore()

const { useActions } = useVuex(null, store)
const { apiLogout, apiProfilesList } = useActions([
  'apiLogout',
  'apiProfilesList',
])

const user = computed(() => store.state.auth.user)
const profile = computed(() => store.state.auth.profile)
const profilesCount = ref(0)

const menuIcon = ref(null)
const menu = ref(null)
const menuItems = ref([])
const createProfileDlg = ref(null)
const selectProfileDlg = ref(null)

function menuClick(e) {
  const rect = menuIcon.value.getBoundingClientRect(e.target)
  menu.value.show(rect.right, rect.bottom + 11, {})
}

onMounted(async () => {
  try {
    const profiles = (await apiProfilesList()).content
    profilesCount.value = profiles.length
  } catch (e) {
  }

  menuItems.value = [
    { title: 'Settings', routeName: 'Account', separator: true, icon: 'mdi-card-account-details-outline' },
    { title: 'Team profile', routeName: 'Profile', separator: false, icon: 'mdi-cog-outline' },
    ...profilesCount.value > 0 ? [{ title: 'Switch profile', click: () => selectProfileDlg.value.show(), separator: false, icon: 'mdi-account-supervisor-outline' }] : [],
    { title: 'Create profile', click: () => createProfileDlg.value.show(), separator: true, icon: 'mdi-account-plus-outline' },
    ...user.value?.role === UserRoles.SUPERUSER ? [{ title: 'Platform settings', routeName: 'PlatformSettings', separator: true, icon: 'mdi-account-group-outline' }] : [],
    { title: 'Logout', click: apiLogout, separator: false, icon: 'mdi-exit-to-app' },
  ]
})

</script>

<template>
  <div class="user-button__wrapper">
    <div class="user-profile-wrapper">
      <div class="title">{{ user?.email }}</div>
      <div class="profile-name">{{ profile?.name }}</div>
    </div>
    <div class="avatar" ref="menuIcon" @click.stop="menuClick">
      <v-icon class="avatar-img">mdi-account</v-icon>
    </div>
  </div>

  <CreateProfileDialog ref="createProfileDlg" />
  <SelectProfileDialog ref="selectProfileDlg" />
  <PopupMenu
    :items="menuItems"
    ref="menu"
    position="right"
  />
</template>

<style lang="scss" scoped>
.user-button__wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  font: 500 13px/16px 'Inter';
}

.user-profile-wrapper {
  display: flex;
  flex-direction: column;
  text-align: right;
  max-width: 200px;
}

.profile-name {
  font: 500 13px 'Inter';
  color: #8B9FB0;;
  margin-top: 4px;
}

.title {
  font: 500 13px/16px 'Inter';
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.avatar {
  margin-left: 12px;
  position: relative;
  display: flex;
  transition: 0.4s;
  cursor: pointer;
}

.avatar-img {
  height: 40px;
  width: 40px;
  border: 1px solid #00d7ff;
  border-radius: 50px;
}

.title * {
  font-size: 13px;
  text-decoration: none;
}

.dlg-create-profile :deep(.wrapper) {
  min-width: 339px;
}
</style>
