<script setup>
import { onMounted, onUnmounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useVuex } from '@vueblocks/vue-use-vuex'
import '@stripe/stripe-js'
import { UpgradeBillingPlanDialog } from '@/components/creator'

const store = useStore()
const router = useRouter()
const { useActions } = useVuex(null, store)
const { apiPing } = useActions(['apiPing'])

let tokenRefresher = null

onMounted(() => {
  if (!tokenRefresher) {
    tokenRefresher = setInterval(() => {
      if (store.state.auth) {
        const tokenExpiration = store.state.auth.exp - Date.now() / 1000
        if (tokenExpiration > 0 && tokenExpiration < 300) {
          apiPing()
        }
      }
    }, 60000)
  }
})

onUnmounted(() => {
  if (tokenRefresher) {
    clearInterval(tokenRefresher)
    tokenRefresher = null
  }
})

function notificationLinkClick(path) {
  if (path === 'Profile') {
    router.push({ name: 'Profile', query: { tab: 'Billing' } })
  }
}
window.notificationLinkClick = notificationLinkClick
</script>

<template>
  <v-app class="app">
    <router-view class="wrapper"></router-view>
  </v-app>
  <div class="g-deprecated" v-if="$route.meta.isDeprecated">
    <router-view name="DeprecatedHome"></router-view>
  </div>
  <notifications position="top center"/>

  <UpgradeBillingPlanDialog />
</template>

<style scoped lang="scss">
.app {
  min-height: 100%;
  height: 100%;
  background: rgb(20, 15, 37);
  background: linear-gradient(
    55deg,
    rgba(20, 15, 37, 1) 0%,
    rgba(31, 56, 45, 1) 15%,
    rgba(20, 15, 37, 1) 25%,
    rgba(1, 1, 1, 1) 35%,
    rgba(12, 6, 16, 1) 50%,
    rgba(31, 15, 34, 1) 70%,
    rgba(90, 59, 67, 1) 100%
  );
  background-repeat: no-repeat;
  display: flex;
}

.app :deep(.v-application__wrap) {
  height: 100%;
  display: flex;
}

.wrapper {
  min-width: 320px;
  max-width: 1200px;
  margin: auto;
}
</style>
