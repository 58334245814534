<script setup>
import { ref, computed, onMounted, watch } from 'vue'
import { useStore } from 'vuex'
import { useVuex } from '@vueblocks/vue-use-vuex'
import { useRouter } from 'vue-router'
import { TextInput, FileInput, FileDrop, Button, LoadingIndicator } from '@/components/creator'
import { ContentItem, Project, Content } from '@/helpers/api/models'
import { deepClone } from '@/utils/common'
import creatorItemTypes from '@/helpers/creatorItemTypes'
import { standardRules } from '@/helpers/validationRules'
import { processError } from '@/helpers/errors'

const state = ref({
  requestInProgress: false,
  isLoading: false,
  isUpdating: false,
  isDeleting: false,
})

const store = useStore()
const { useActions } = useVuex(null, store)
const {
  apiProjectsCreate,
  apiContentItemsCreate,
  apiConvertFbx,
  apiCampaignsGet,
  apiCampaignsList,
  apiProfilesGet,
  apiProjectsNewId,
} = useActions([
  'apiProjectsCreate',
  'apiContentItemsCreate',
  'apiConvertFbx',
  'apiCampaignsGet',
  'apiCampaignsList',
  'apiProfilesGet',
  'apiProjectsNewId',
])

const router = useRouter()

const profileId = computed(() => store.state.auth.profile.id)
const campaignId = ref(null)

const contentItem = ref(deepClone(ContentItem))
const project = ref(deepClone(Project))

const textInputName = ref(null)
const fileInputGlb = ref(null)
const fileInputUsdz = ref(null)
const isValid = ref(false)
const isConvertError = ref(false)

const formValid = computed(() =>
  isValid.value &&
  fileInputGlb.value?.valid &&
  (!isConvertError.value || fileInputUsdz.value?.valid)
)

watch(
  () => contentItem.value.media.glbmodel,
  () => {
    isConvertError.value = false
    contentItem.value.media.custom_usdz = false
    contentItem.value.media.usdzmodel = ''
  }
)

async function createAr () {
  try {
    state.value.requestInProgress = true
    state.value.isUpdating = true

    if (!isConvertError.value) {
      try {
        const convertResult = (await apiConvertFbx({
          filename: contentItem.value.media.glbmodel,
        })
        ).content

        contentItem.value.media.glbmodel = convertResult.glb
        contentItem.value.media.usdzmodel = convertResult.usdz
      } catch (e) {
        processError(e)
        isConvertError.value = true
        return
      }
    } else {
      contentItem.value.media.custom_usdz = true
    }

    contentItem.value.type = 'AR'
    contentItem.value.name = project.value.name

    contentItem.value = (
      await apiContentItemsCreate({
        data: contentItem.value,
      })
    ).content

    const content = deepClone(Content)
    content.priority = 1
    content.content_item = contentItem

    const [id, campaign, profile] = await Promise.all([
      (await apiProjectsNewId()).content.id,
      (
        await apiCampaignsGet({
          id: campaignId.value,
        })
      ).content,
      (
        await apiProfilesGet({ id: profileId.value })
      ).content,
    ])

    project.value.id = id
    project.value.campaign = campaign
    project.value.profile = profile
    project.value.content.push(content)

    const projectCreateResult = (
      await apiProjectsCreate({
        data: project.value,
      })
    ).content

    router.push({
      name: 'ArItem',
      params: { id: projectCreateResult.id },
    })
  } catch (error) {
    processError(error)
  } finally {
    state.value.requestInProgress = false
    state.value.isUpdating = false
  }
}

onMounted(async () => {
  try {
    const campaignsList = (await apiCampaignsList()).content
    campaignId.value = campaignsList[0]?.id
  } catch (error) {
    processError(error)
  }
})
</script>

<template>
  <div class="ar-new-wrapper">
    <div class="g-title-wrapper">
      <h1 class="g-title">New AR</h1>
    </div>

    <div class="g-content">
      <div class="g-form-upload-wrapper">
        <v-form v-model="isValid">
          <LoadingIndicator :state="state" all />

          <TextInput
            ref="textInputName"
            v-model="project.name"
            name="name"
            type="text"
            label="AR name"
            autofocus
            :rules="[standardRules.required]"
          />

          <FileDrop
            ref="textFileDrop"
            :fileInput="fileInputGlb"
            :fileSpecs="creatorItemTypes.glb.textSpecs"
          />

          <div class="convert-error-notice">
            <div class="convert-error-notice__icon-wrapper">
              <v-icon class="convert-error-notice__icon" :size="12">
                mdi-exclamation-thick
              </v-icon>
            </div>
            <div class="convert-error-notice__text">
              If your GLB file contains animation, use the additional USDZ/Reality uploader. Conversion is temporarily unavailable and could be failed.
            </div>
          </div>

          <FileInput
            ref="fileInputGlb"
            v-model="contentItem.media.glbmodel"
            :extensions="creatorItemTypes.glb.storageUploadExt"
            placeholder="AR file"
            required
            :validator="{}"
          />

          <div class="convert-error-wrapper">
            <FileInput
              ref="fileInputUsdz"
              v-model="contentItem.media.usdzmodel"
              :extensions="['.usdz', '.reality']"
              placeholder="USDZ/Reality"
              :required="isConvertError"
              :validator="{}"
            />
          </div>

          <div class="btn-create-wrapper">
            <Button
              primary
              class="btn-create"
              @click="createAr"
              :disabled="!formValid || state.requestInProgress"
              :loading="state.isUpdating"
              >Create</Button
            >
          </div>
        </v-form>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.file-drop-wrapper {
  margin-top: 38px;
}

.file-input-wrapper {
  margin-top: 28px;
}

.btn-create-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 72px;
}

.convert-error-notice {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 16px;
}

.convert-error-notice__text {
  font: 400 10px 'Inter';
  color: #F1B849;
  margin-left: 10px;
}

.convert-error-notice__icon {
  color: black;
  position: absolute;
  top: 3px;
  left: 3px;
}

.convert-error-notice__icon-wrapper {
  border-radius: 50%;
  background: #F1B849;
  min-width: 17px;
  height: 17px;
  position: relative;
}
</style>
