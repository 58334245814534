<script setup>
import { ref, onMounted, computed } from 'vue'
import { useStore } from 'vuex'
import Dialog from '@/components/common/dialogs/Dialog'
import { PaymentMethod } from '@/components/creator/payments/stripe'
import { useVuex } from '@vueblocks/vue-use-vuex'
import { getLoadingState, wait } from '@/utils/common'
import { BillingPeriod } from '@/components/creator/billing_plans'
import { Button, LoadingIndicator } from '@/components/creator'
import { PaymentMethodExisting } from '@/components/creator/payments/'
import { processError } from '@/helpers/errors'

const clientSecret = ref()
const paymentMethodExisting = ref()
const paymentMethodForm = ref(null)
const billingPeriod = ref()
const paymentMethodEditing = ref(false)

const store = useStore()
const { useActions } = useVuex(null, store)
const {
  apiPaymentCreateSetupIntent,
  apiPaymentMethodGet,
  apiUserSubscriptionSet,
} = useActions([
  'apiPaymentCreateSetupIntent',
  'apiPaymentMethodGet',
  'apiUserSubscriptionSet',
])

const dialog = ref(null)

const state = ref(getLoadingState())
const stateGlobal = computed(() => {
  return {
    requestInProgress: state.value.requestInProgress || paymentMethodForm.value?.state.requestInProgress,
    isLoading: state.value.isLoading || paymentMethodForm.value?.state.isLoading,
    isUpdating: state.value.isUpdating || paymentMethodForm.value?.state.isUpdating,
  }
})

const props = defineProps({
  selectedBillingPeriod: {
    type: String,
    required: true,
  },
  newSubscription: Object,
})

const isWithPrice = computed(() => {
  return props.newSubscription.price
})

const usePaymentMethodExists = computed(() => {
  return paymentMethodExisting?.value?.id && !paymentMethodEditing.value
})

const usePaymentMethodForm = computed(() => {
  return !paymentMethodExisting?.value?.id || paymentMethodEditing.value
})

const show = async () => {
  paymentMethodEditing.value = false
  billingPeriod.value = props.selectedBillingPeriod

  let result = {}

  try {
    state.value.requestInProgress = true
    state.value.isLoading = true

    const dialogPromise = dialog.value.show()

    const results = await Promise.all([
      apiPaymentCreateSetupIntent(),
      apiPaymentMethodGet(),
    ])
    clientSecret.value = results[0].content.client_secret
    paymentMethodExisting.value = results[1].content.payment_methods[0]

    state.value.requestInProgress = false
    state.value.isLoading = false

    result = await dialogPromise
  } catch (error) {
    processError(error)
    result = {
      result: false,
      error: error,
    }
    dialog.value.close(false)
  } finally {
    clientSecret.value = ''
    state.value.requestInProgress = false
    state.value.isLoading = false
  }

  return result
}

async function purchaseClicked () {
  try {
    state.value.requestInProgress = true

    if (usePaymentMethodForm.value) {
      const paymentMethodConfirmResult = await paymentMethodForm.value.formSubmit()

      state.value.isLoading = true

      const requestCount = 5
      let paymentMethodAdded = false

      for (let requestIndex = 0; requestIndex < requestCount; requestIndex++) {
        const apiResult = (await apiPaymentMethodGet()).content.payment_methods[0]
        if (apiResult.id === paymentMethodConfirmResult.paymentMethod) {
          paymentMethodAdded = true
          break
        }

        await wait(2000)
      }

      if (!paymentMethodAdded) {
        throw new Error('Payment method not added')
      }

      state.value.isLoading = false
    }

    state.value.isUpdating = true
    await apiUserSubscriptionSet({ subscriptionId: props.newSubscription.id, billingPeriod: billingPeriod.value })

    dialog.value.close('ok')
  } catch (error) {
    processError(error)
  } finally {
    state.value.requestInProgress = false
    state.value.isLoading = false
    state.value.isUpdating = false
  }
}

function getSummaryProductTitle (period) {
  if (period === 'MONTH') {
    return `$${props.newSubscription.price[period.toLowerCase()]}/month`
  } else {
    return `$${Math.round(props.newSubscription.price[period.toLowerCase()] / 12)}/month x 1 year`
  }
}

function paymentMethodEditClicked () {
  paymentMethodEditing.value = true
}

onMounted(() => {
})

defineExpose({ show })
</script>

<template>
  <Dialog
    :title="`${props.newSubscription.name} plan: Payment details`"
    ref="dialog"
    :buttons="[]"
    class="payment-details__wrapper"
  >
    <LoadingIndicator loading :state="stateGlobal" />
    <div class="wrapper">
      <div class="billing__info">
        <BillingPeriod
          :periods="{
            YEAR: { title: 'Annually', price: `$${Math.round(newSubscription.price.year / 12)}/month` },
            MONTH: { title: 'Monthly', price: `$${newSubscription.price.month}/month` }
          }"
          v-model="billingPeriod"
          :class="{ 'billing-period--with-price': isWithPrice }"
          class="billing-period"
        />

        <div class="payment-method__wrapper">
          <p class="payment-method-title">Payment information</p>

          <PaymentMethodExisting
            :paymentInfo="paymentMethodExisting"
            v-if="usePaymentMethodExists"
            @edit="paymentMethodEditClicked"
          />
          <PaymentMethod
            :clientSecret="clientSecret"
            v-if="usePaymentMethodForm && clientSecret"
            ref="paymentMethodForm"
            class="payment-method"
            showTitle="true"
          />
        </div>
      </div>

      <div class="summary__wrapper">
        <div class="summary__title">Order summary</div>

        <div class="summary-items">
          <div class="summary-item">
            <div class="summary-item__product">{{ getSummaryProductTitle(billingPeriod) }}</div>
            <div class="summary-item__price">{{ `$${newSubscription.price[billingPeriod.toLowerCase()]}` }}</div>
          </div>
        </div>

        <div class="summary-total">
          <div class="summary-total__title">Total</div>
          <div class="summary-total__value">{{ `$${newSubscription.price[billingPeriod.toLowerCase()]}` }}</div>
        </div>

        <div class="summary__notice">
          You`ll be charged ${{ newSubscription.price[billingPeriod.toLowerCase()] }}/{{ billingPeriod.toLowerCase() }} until you cancel the subscription.
        </div>

        <Button
          class="summary__button-purchase"
          :disabled="usePaymentMethodForm && !clientSecret"
          @click="purchaseClicked"
          primary
          small
        >
          Purchase
        </Button>
      </div>
    </div>
  </Dialog>
</template>

<style lang="scss" scoped>

.payment-details__wrapper > :deep(.wrapper) {
  min-width: 339px;
}

.billing-period--with-price :deep(.period-item__title) {
  width: 140px;
}

.wrapper {
  display: flex;
  flex-direction: row;
}

.summary__wrapper {
  background: #182734;
  border: 1px solid #334656;
  border-radius: 8px;
  align-self: flex-start;
  width: 300px;
  padding: 14px 24px;
  margin-left: 17px;
}

.summary-items {
  border-bottom: 1px solid #D2D9E7;
  padding-bottom: 7px;
  margin-top: 8px;
}

.summary__title {
  font: 400 16px/24px 'Poppins';
  color: #FFFFFF;
}

.summary-item {
  font: 400 12px/15px 'Inter';
  color: #909EAF;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.summary-total {
  margin-top: 13px;
  display: flex;
  flex-direction: row;
  justify-content: space-between
}

.summary-total__title,
.summary-total__value {
  font: 500 18px 'Poppins';
  color: #FFFFFF;
}

.summary__notice {
  font: 400 12px/15px 'Inter';
  color: #909EAF;
  margin-top: 22px;
}

.billing-period {
  color: white;
}

.payment-method__wrapper {
  margin-top: 20px;
}

.summary__button-purchase {
  width: 100%;
  margin-top: 30px;
}

.payment-method-title {
  font: 400 16px 'Poppins';
  color: #FFFFFF;
  margin-bottom: 11px;
}

.billing__info {
  width: 350px;
}
</style>
