<script setup>
import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useVuex } from '@vueblocks/vue-use-vuex'
import {
  Button,
  TabNav,
  Tabs,
  Tab,
  TextInput,
  RichTextEditor,
  FileInput,
  ModelViewer,
  NftPropertiesList,
  Tooltip,
  LoadingIndicator,
} from '@/components/creator'
import { decodeUriFrom, deepClone } from '@/utils/common'
import { ContentItem, ContentItemType } from '@/helpers/api/models'
import { standardRules } from '@/helpers/validationRules'
import { notify } from '@kyvg/vue3-notification'
import Dialog from '@/components/common/dialogs/Dialog'
import * as DialogButtons from '@/components/common/dialogs/DialogButtons'
import { uuid4 } from '@sentry/utils'
import { processError } from '@/helpers/errors'

const state = ref({
  requestInProgress: false,
  isLoading: false,
  isUpdating: false,
  isDeleting: false,
})

const store = useStore()
const { useActions } = useVuex(null, store)
const {
  apiContentItemsGet,
  apiContentItemsUpdate,
  apiContentItemsDelete,
  setTitle,
} = useActions([
  'apiContentItemsGet',
  'apiContentItemsUpdate',
  'apiContentItemsDelete',
  'setTitle',
])
const route = useRoute()
const router = useRouter()

const contentItem = ref(deepClone(ContentItem))
const contentItemId = route.params.id

const deleteContentItemDialog = ref(null)
const form = ref(null)
const tabPreviews = ref('')

const buttonRules = {
  buttonPrimaryNameRequired: value => (!!value || !buttonPrimaryLink.value) || 'Link requires button name',
  buttonSecondaryNameRequired: value => (!!value || !buttonSecondaryLink.value) || 'Link requires button name',
}

function setButtonData (index, title, value) {
  if (index === 1) {
    if (!contentItem.value.buttons.length) {
      contentItem.value.buttons.push({ title: '', value: '', order: 0, id: uuid4(), type: 'LINK' })
    }
  }

  if ((index === 0 && contentItem.value.buttons.length === 0) ||
      (index === 1 && contentItem.value.buttons.length === 1)) {
    contentItem.value.buttons.push({ title: title, value: value, order: index, id: uuid4(), type: 'LINK' })
  } else {
    if (title !== undefined) {
      contentItem.value.buttons[index].title = title
    }

    if (value !== undefined) {
      contentItem.value.buttons[index].value = value
    }
  }
}

const buttonPrimaryLabel = computed({
  get () {
    return contentItem.value?.buttons?.length > 0 ? contentItem.value.buttons[0]?.title : ''
  },
  set (value) {
    setButtonData(0, value, undefined)
  },
})
const buttonPrimaryLink = computed({
  get () {
    return contentItem.value?.buttons?.length > 0 ? contentItem.value.buttons[0]?.value : ''
  },
  set (value) {
    setButtonData(0, undefined, value)
  },
})
const buttonSecondaryLabel = computed({
  get () {
    return contentItem.value?.buttons?.length > 1 ? contentItem.value.buttons[1]?.title : ''
  },
  set (value) {
    setButtonData(1, value, undefined)
  },
})
const buttonSecondaryLink = computed({
  get () {
    return contentItem.value?.buttons?.length > 1 ? contentItem.value.buttons[1]?.value : ''
  },
  set (value) {
    setButtonData(1, undefined, value)
  },
})
const toolTip = ref()

const previewSrc = computed(() => {
  let src = ''
  if (
    !contentItem.value ||
    !contentItem.value?.type ||
    !contentItem.value?.media
  ) {
    return ''
  }

  switch (contentItem.value?.type) {
    case ContentItemType.AR:
    case ContentItemType.AVATAR:
    case ContentItemType.GLB:
      src = contentItem.value?.media.glbmodel
      break
    case ContentItemType.IMAGE:
      src = contentItem.value?.media.image
      break
    case ContentItemType.SOUND:
      src = contentItem.value?.media.sound
      break
    case ContentItemType.VIDEO:
      src = contentItem.value?.media.video
      break
  }
  if (!src) {
    return ''
  }

  return src
})

function close () {
  if (route.query.from) {
    router.push(decodeUriFrom(route.query.from))
  } else {
    router.push({
      name: 'Storage',
    })
  }
}

const fileInputGlb = ref(null)
const fileInputGlbMobile = ref(null)
const fileInputUsdz = ref(null)
const fileInputImage = ref(null)
const fileInputSound = ref(null)
const fileInputVideo = ref(null)

const isBaseFormValid = ref(false)
const isFormValid = computed(() => {
  return isBaseFormValid.value && isFileInputByTypeValid()
})

function isFileInputByTypeValid () {
  switch (contentItem.value?.type) {
    case ContentItemType.AR:
      return (
        isBaseFormValid.value &&
        fileInputGlb.value?.valid &&
        fileInputUsdz.value?.valid
      )
    case ContentItemType.AVATAR:
      return fileInputGlb.value?.valid
    case ContentItemType.GLB:
      return fileInputGlb.value?.valid && (fileInputGlbMobile.value === null || fileInputGlbMobile.value?.valid)
    case ContentItemType.IMAGE:
      return fileInputImage.value?.valid
    case ContentItemType.SOUND:
      return fileInputSound.value?.valid
    case ContentItemType.VIDEO:
      return fileInputVideo.value?.valid
    default:
      return false
  }
}

async function loadContentItem () {
  try {
    state.value.requestInProgress = true
    state.value.isLoading = true
    contentItem.value = (
      await apiContentItemsGet({ data: contentItemId })
    ).content

    setTitle(`${contentItem.value.name} | Storage item`)
  } catch (error) {
    processError(error)
  } finally {
    state.value.isLoading = false
    state.value.requestInProgress = false
  }
}

async function updateContentItem () {
  try {
    state.value.requestInProgress = true
    state.value.isUpdating = true
    contentItem.value = (
      await apiContentItemsUpdate({
        data: contentItem.value,
      })
    ).content

    notify({
      type: 'success',
      text: 'Storage item updated',
      timeout: 30000,
    })

    setTitle(`${contentItem.value.name} | Storage item`)
  } catch (error) {
    processError(error)
  } finally {
    state.value.isUpdating = false
    state.value.requestInProgress = false
  }
}

async function deleteContentItem () {
  try {
    state.value.requestInProgress = true
    state.value.isDeleting = true
    await apiContentItemsDelete({ id: contentItemId })
    close()
  } catch (error) {
    processError(error)
  } finally {
    state.value.isDeleting = false
    state.value.requestInProgress = false
  }
}

const deleteContentItemClicked = async () => {
  const result = await deleteContentItemDialog.value.show()
  if (result === 'delete') {
    await deleteContentItem()
  }
}

function onUpdateProperties (properties) {
  properties.forEach((property, index) => {
    if (!property.id) {
      property.id = uuid4()
    }

    property.order = index
  })
}

onMounted(async () => {
  await loadContentItem()
  form.value.validate()
})

function secondButtonInfoHovered (hovered, iconElement) {
  if (hovered) {
    const rect = iconElement.getBoundingClientRect()

    const toolTipLeft = window.pageXOffset + rect.left + rect.width / 2 - 1
    const toolTipTop = window.pageYOffset + rect.top + rect.height + 5
    toolTip.value.show(toolTipLeft, toolTipTop)
  } else {
    toolTip.value.hide()
  }
}
</script>

<template>
  <div>
    <div class="g-title-wrapper">
      <h1 class="g-title">
        {{ contentItem.name }}
      </h1>
    </div>

    <div class="g-content g-shrink">
      <div class="g-content__header">
        <div class="g-tabs__wrapper">
          <TabNav
            :items="['Settings']"
            v-model="tab"
            urlParam="tab"
          />
        </div>
      </div>
      <div class="g-content__inner g-shrink">
        <Tabs v-model="tab" content-scroll>
          <Tab value="Settings">
            <div class="g-content__columns-wrapper g-shrink">
              <LoadingIndicator :state="state" all />
              <div class="g-content__column-first">
                <v-form
                  v-model="isBaseFormValid"
                  :loading="state.requestInProgress"
                  @submit.prevent=""
                  ref="form"
                >
                  <div class="g-section-title">About</div>

                  <template v-if="contentItem.type === 'AVATAR'">
                    <TextInput
                      v-model="contentItem.name"
                      name="name"
                      type="text"
                      label="Avatar name"
                      :rules="[standardRules.required]"
                    />

                    <FileInput
                      ref="fileInputGlb"
                      v-model="contentItem.media.glbmodel"
                      :extensions="['.glb', '.gltf']"
                      placeholder="Avatar file"
                      required
                      :validator="{}"
                    />

                    <FileInput
                      ref="fileInputGlbMobile"
                      v-model="contentItem.media.glbmodel_mobile"
                      :extensions="['.glb', '.gltf']"
                      placeholder="Avatar for Mobile version"
                      :validator="{}"
                    />

                    <FileInput
                      v-model="contentItem.media.poster"
                      :extensions="['.jpg', '.jpeg', '.png']"
                      :validator="{}"
                      placeholder="Preview"
                    />
                  </template>

                  <template v-else>
                    <FileInput
                      v-show="['GLB', 'AVATAR'].includes(contentItem.type)"
                      ref="fileInputGlb"
                      v-model="contentItem.media.glbmodel"
                      :extensions="['.glb', '.gltf']"
                      placeholder="File"
                      required
                      :validator="{}"
                    />

                    <FileInput
                      v-show="['IMAGE'].includes(contentItem.type)"
                      ref="fileInputImage"
                      v-model="contentItem.media.image"
                      :extensions="['.jpg', '.jpeg', '.png']"
                      :validator="{}"
                      required
                      placeholder="File"
                    />

                    <FileInput
                      v-show="['VIDEO'].includes(contentItem.type)"
                      ref="fileInputVideo"
                      v-model="contentItem.media.video"
                      :extensions="['.mp4']"
                      :validator="{}"
                      placeholder="File"
                    />

                    <FileInput
                      v-show="['GLB'].includes(contentItem.type)"
                      v-model="contentItem.media.poster"
                      :extensions="['.jpg', '.jpeg', '.png']"
                      :validator="{}"
                      placeholder="Poster"
                    />

                    <TextInput
                      v-model="contentItem.name"
                      name="name"
                      type="text"
                      label="Title"
                      :rules="[standardRules.required]"
                    />

                    <RichTextEditor
                      v-model="contentItem.description"
                      label="Description"
                      class="input-description"
                      name="description"
                      max-height="300px"
                    />

                    <TextInput
                      v-model="contentItem.price"
                      type="text"
                      label="Price"
                    />

                    <NftPropertiesList
                      v-model="contentItem.properties"
                      @update:modelValue="onUpdateProperties"
                    />

                    <div class="g-section">
                      <div class="g-section-title">Button</div>

                      <TextInput
                        v-model="buttonPrimaryLabel"
                        type="text"
                        label="Button label"
                        :rules="[buttonRules.buttonPrimaryNameRequired]"
                        maxlength="28"
                      />

                      <TextInput
                        v-model="buttonPrimaryLink"
                        type="text"
                        label="Button link"
                        @update:modelValue="form.validate()"
                      />

                      <TextInput
                        v-model="buttonSecondaryLabel"
                        type="text"
                        label="Second button label"
                        :icons="['mdi-information-outline']"
                        @iconMouseEnter="(name, iconElement) => secondButtonInfoHovered(true, iconElement)"
                        @iconMouseLeave="(name, iconElement) => secondButtonInfoHovered(false, iconElement)"
                        :rules="[buttonRules.buttonSecondaryNameRequired]"
                        maxlength="28"
                      />

                      <TextInput
                        v-model="buttonSecondaryLink"
                        type="text"
                        label="Second button link"
                        @update:modelValue="form.validate()"
                      />
                    </div>
                  </template>
                </v-form>
              </div>
              <div class="g-content__column-second">
                <template v-if="contentItem.type !== 'AVATAR'">
                  <ModelViewer
                    :src="previewSrc"
                    :poster="contentItem.media.poster"
                    label="Preview"
                    :type="contentItem.type"
                  />
                </template>
                <template v-else>
                  <TabNav :items="['Preview', 'Custom Preview']" v-model="tabPreviews" ghost>
                  </TabNav>
                  <Tabs v-model="tabPreviews">
                    <Tab value="Preview">
                      <ModelViewer
                        :src="previewSrc"
                        :poster="contentItem.media.poster"
                        :type="contentItem.type"
                      />
                    </Tab>
                    <Tab value="Custom Preview">
                      <ModelViewer
                        :src="contentItem.media.poster"
                        type="IMAGE"
                      />
                    </Tab>
                  </Tabs>
                </template>
              </div>
            </div>

            <div class="g-content__footer">
              <div class="g-controls__wrapper">
                <div class="g-controls__col-first">
                  <Button
                    class="g-controls__control"
                    @click="close"
                    :disabled="state.requestInProgress"
                    >Close</Button
                  >
                  <Button
                    primary
                    class="g-controls__control"
                    @click="updateContentItem"
                    :disabled="!isFormValid || state.requestInProgress"
                    :loading="state.isUpdating"
                    >Update</Button
                  >
                </div>
                <div class="g-controls__col-last">
                  <Button
                    class="g-controls__control"
                    @click="deleteContentItemClicked"
                    :disabled="state.requestInProgress"
                    :loading="state.isDeleting"
                    alert
                    >Delete</Button
                  >
                </div>
              </div>
            </div>
          </Tab>
        </Tabs>
      </div>
    </div>
    <Dialog
      title="Delete storage item?"
      ref="deleteContentItemDialog"
      :buttons="[DialogButtons.Cancel, DialogButtons.DeletePrimary]"
    >
      <div class="g-text-center">
        The storage item will be deleted with all the information.<br />
      </div>
    </Dialog>

    <Tooltip ref="toolTip">
      Button disabled if input is empty
    </Tooltip>
  </div>
</template>

<style lang="scss" scoped>
.g-content__columns-wrapper {
  flex: 1;
}

.g-controls__col-first,
.g-controls__col-last {
  flex: 1;
}

.g-controls__col-last {
  align-items: flex-end;
}

.input-description {
  margin-top: 2px;
  margin-bottom: 18px;
}

</style>
