<script setup>
import { computed, onMounted, ref, watch, nextTick, onBeforeMount } from 'vue'
import { useStore } from 'vuex'
import { useVuex } from '@vueblocks/vue-use-vuex'
import Fabric from '@arhead/smart-contract-sdk'
import BlockchainsDialog from '@/components/creator/nft/NftBlockchainsDialog.vue'
import { useRoute, useRouter } from 'vue-router'
import { notify } from '@kyvg/vue3-notification'
import { wait } from '@/utils/common'

import {
  TextInput,
  TextArea,
  Select,
  NftStatusPanel,
  NftPropertiesList,
  Button,
  LoadingIndicator,
} from '@/components/creator'
import { processError } from '@/helpers/errors'

const store = useStore()
const { useActions } = useVuex(null, store)
const {
  apiBlockchainsGet,
  apiSignMintGet,
  apiMintToken,
  apiConfirmMint,
} = useActions([
  'apiBlockchainsGet',
  'apiSignMintGet',
  'apiMintToken',
  'apiConfirmMint',
])

const route = useRoute()
const router = useRouter()

const props = defineProps({
  entityType: String,
  entity: Object,
})

const metadata = ref([])

const state = ref({
  requestInProgress: false,
  isLoading: false,
  isUpdating: false,
  isDeleting: false,
})

const wallet = ref(null)
const _currentBlockchain = ref()
const currentBlockchain = computed({
  get () {
    return _currentBlockchain.value
  },
  set (value) {
    _currentBlockchain.value = value

    if (!currentBlockchain.value) {
      currentWallet.value = null
      return
    }

    currentWallet.value = blockchainWallets.value[0].value
  },

})
const currentWallet = ref(null)
const blockchains = ref([])
const blockchainsList = computed(() => {
  const result = []
  for (const name in blockchains.value) {
    result.push({
      value: blockchains.value[name],
      text: name,
    })
  }
  return result
})
const walletSignedIn = ref(false)

const _walletNetworkName = ref('')
const walletNetworkName = computed({
  get () {
    return _walletNetworkName.value
  },
  set (value) {
    if (wallet.value && walletOptions.value.changableNetwork) {
      wallet.value.setNetwork(value)
    }
  },
})

const blockchainsDlg = ref(null)

const blockchainWallets = computed(() => {
  if (!currentBlockchain.value) {
    return []
  }

  const result = []

  for (const walletName of Fabric.supportedProtocols[currentBlockchain.value.protocol].wallets) {
    const walletTmp = Fabric.createWallet(walletName, currentBlockchain.value.protocol)
    result.push({
      value: walletName,
      text: walletTmp.getWalletName(),
    })
  }

  return result
})

const walletOptions = ref(null)
const currentAccountAddress = ref('')

const currentNetworkName = computed(() => {
  if (!walletNetworkName.value) return undefined

  for (const network of currentBlockchain.value.networks) {
    if (network.name === walletNetworkName.value) {
      return network.name
    }
  }

  return undefined
})

const currentNetwork = computed(() => {
  if (!currentBlockchain.value || !currentNetworkName.value) return undefined

  return currentBlockchain.value.networks.find((x) => x.name === currentNetworkName.value)
})

const blockchainNetworksSelectList = computed(() => {
  const result = []

  if (!currentBlockchain.value || !currentNetworkName.value) return result

  for (const network of currentBlockchain.value.networks) {
    result.push({
      value: network.name,
      text: network.title,
    })
  }

  return result
})

const currentNetworkTypeTitle = computed(() => {
  if (!walletNetworkName.value) {
    return 'not selected'
  }

  if (!currentNetwork.value) {
    return 'unknown network'
  }

  return currentNetwork.value.type_title
})

const mintButtonTitle = computed(() => {
  if (!currentNetwork.value) {
    return 'Mint'
  }

  return (isTestnet.value) ? 'Test mint' : 'Final mint'
})

const isTestnet = computed(() => {
  return !currentNetwork.value.is_mainnet
})

const buttonSignInVisible = computed(() => {
  return wallet.value?.walletOptions.signInRequired && !walletSignedIn.value
})

const buttonSignOutVisible = computed(() => {
  return wallet.value?.walletOptions.signInRequired && walletSignedIn.value
})

watch(
  () => currentWallet.value,
  async () => {
    if (!currentWallet.value) {
      currentNetwork.value = null
      walletOptions.value = null
      wallet.value = null
      return
    }

    wallet.value = Fabric.createWallet(currentWallet.value, currentBlockchain.value.protocol)

    if (!wallet.value) {
      console.error('Protocol not found')
      return
    }

    await wallet.value.initialize(handleWalletChange)

    walletOptions.value = wallet.value.walletOptions

    handleWalletChange()
  }
)

async function handleWalletChange () {
  if (!currentBlockchain.value || !wallet.value) return

  try {
    state.value.requestInProgress = true

    _walletNetworkName.value = wallet.value.networkName
    if (!_walletNetworkName.value) {
      return
    }

    if (walletOptions.value.signInRequired) {
      if (!await updateSignedInState()) {
        await walletSignIn()
        return
      }
    }

    currentAccountAddress.value = await wallet.value.getAccountAddress()

    await wallet.value.connectToContract(currentNetwork.value.contract)
  } finally {
    state.value.requestInProgress = false
  }
}

/*
const openSeaLink = computed(() => {
  return `https://${currentNetwork.value.opensea_domain}/assets/${currentNetwork.value.opensea_network}/${currentNetworkAddress.value}/${project.value.nft_token_id}`
})
*/

async function updateSignedInState () {
  walletSignedIn.value = await wallet.value.isSignedIn()
  return walletSignedIn.value
}

async function walletSignOut () {
  await wallet.value.signOut()
  await updateSignedInState()

  currentBlockchain.value = null
  emit('needClose')
}

async function walletSignIn () {
  const query = {
    ...route.query,
    signin: 1,
    blockchain: currentBlockchain.value.name,
    wallet: currentWallet.value,
    network: currentNetwork.value.name,
  }

  await router.replace({ query: query })
  await wallet.value.signIn(currentNetwork.value.contract)
}

async function mint () {
  try {
    state.value.requestInProgress = true
    state.value.isUpdating = true

    const apiResult = (await apiSignMintGet({
      blockchain: currentBlockchain.value.name,
      network: currentNetwork.value.name,
      contractMethod: 'mintAssignedToken',
      entityType: props.entityType,
      entityId: props.entity.id,
      to: currentAccountAddress.value,
    })).content

    if (currentBlockchain.value.name === 'NEAR') {
      await apiMintToken({
        blockchain: currentBlockchain.value.name,
        network: currentNetwork.value.name,
        entityType: props.entityType,
        entityId: props.entity.id,
        to: currentAccountAddress.value,
        tokenId: apiResult.token_id,
        salt: apiResult.salt,
        signature: apiResult.signature,
      })
    } else {
      await wallet.value.trustedMint(apiResult.token_id, apiResult.salt, apiResult.signature)
    }

    await apiConfirmMint({ tokenId: apiResult.token_id })

    notify({
      type: 'success',
      text: 'NFT minted',
    })
  } catch (e) {
    processError(e)
  } finally {
    state.value.requestInProgress = false
    state.value.isUpdating = false
  }
}

async function chooseBlockchain () {
  const selectedItem = await blockchainsDlg.value.show()

  if (selectedItem) {
    currentBlockchain.value = blockchains.value[selectedItem]
  }

  return selectedItem
}

function agreeOnProtocols (blockchainsServer) {
  const result = {}
  for (const blockchain of blockchainsServer) {
    if (Fabric.supportedProtocols[blockchain.protocol]) {
      result[blockchain.name] = blockchain
    }
  }

  if (!Object.keys(result).length) {
    throw new Error('No supported blockchains')
  }

  return result
}

onMounted(async () => {

})

onBeforeMount(async () => {
  try {
    state.value.isLoading = true
    state.value.requestInProgress = true
    const blockchainsServer = (await apiBlockchainsGet()).content
    blockchains.value = agreeOnProtocols(blockchainsServer)
  } catch (e) {
    processError(e)
  } finally {
    state.value.requestInProgress = false
    state.value.isLoading = false
  }

  const fromSignin = route.query.signin === '1'

  if (fromSignin) {
    const fromSigninBlockchain = blockchains.value[route.query.blockchain]
    const fromSigninWallet = route.query.wallet
    const fromSigninNetwork = fromSigninBlockchain.networks.find((x) => x.name === route.query.network)
    const walletTmp = Fabric.createWallet(fromSigninWallet, fromSigninBlockchain.protocol)

    await walletTmp.initialize(() => {})

    if (walletTmp.walletOptions.changableNetwork) {
      await walletTmp.setNetwork(fromSigninNetwork.name)
    }

    if (await walletTmp.isSignedIn()) {
      currentBlockchain.value = fromSigninBlockchain
      currentWallet.value = fromSigninWallet
      await wait(10)

      if (walletTmp.walletOptions.changableNetwork) {
        walletNetworkName.value = fromSigninNetwork.name
      }
    } else {
      emit('needClose')
    }

    await router.replace({ query: {} })
  }
})

async function onVisible () {
  if (!currentBlockchain.value) {
    if (!await chooseBlockchain()) {
      nextTick(() => {
        emit('needClose')
      })
    }
  }
}

defineExpose({ onVisible })

const emit = defineEmits(['needClose'])

const nftPropertiesList = computed({
  get () {
    return metadata.value.filter(
      (el) => el.key !== 'created_by' && el.key !== 'description'
    )
  },
  set (newVal) {
    const createdByPrev = createdBy.value
    const descriptionPrev = description.value

    metadata.value = newVal
    if (createdByPrev) {
      metadata.value.created_by = createdByPrev
    }
    if (descriptionPrev) {
      metadata.value.description = descriptionPrev
    }
  },
})

const createdBy = computed({
  get () {
    return getNftProp('created_by')
  },
  set (newValue) {
    setNftProp('created_by', newValue)
  },
})

const description = computed({
  get () {
    return getNftProp('description')
  },
  set (newValue) {
    setNftProp('description', newValue)
  },
})

function getNftProp (name) {
  return metadata.value.find(
    (x) =>
      // eslint-disable-next-line
      x.nft && x.key == name
  )?.value
}

function setNftProp (name, value) {
  const existsProp = metadata.value.findIndex(
    // eslint-disable-next-line
    (x) => x.nft && x.key == name
  )

  if (existsProp >= 0) {
    if (value === undefined) {
      // was deleted
      metadata.value.splice(existsProp, 1)
    } else {
      metadata.value[existsProp].key = name
      metadata.value[existsProp].value = value
    }
  } else {
    metadata.value.push({
      key: name,
      value: value,
      nft: true,
    })
  }
}
</script>

<template>
  <div class="nft-studio__wrapper">
    <LoadingIndicator :state="state" all />

    <div class="blockchain-switch-wrapper">
      <div class="blockchain-switch" @click="chooseBlockchain">
        <div class="blockchain-switch__title">Change blockchain</div>
        <v-icon :size="20" class="blockchain-switch__icon">
          mdi-chevron-right
        </v-icon>
      </div>

      <div
        class="wallet-logout"
        @click="walletSignOut"
        v-if="buttonSignOutVisible"
      >
        Logout
      </div>
  </div>

    <Select
      v-model="currentBlockchain"
      :items="blockchainsList"
      placeHolder="Blockchain"
      v-if="false"
    />

    <Select
      v-model="currentWallet"
      :items="blockchainWallets"
      placeHolder="Wallet"
      v-if="false"
    />

    <Select
      v-model="walletNetworkName"
      :items="blockchainNetworksSelectList"
      placeHolder="Network"
      v-if="walletOptions?.changableNetwork"
    />

    <div class="wallet-signing-wrapper">
      <Button
        :disabled="state.requestInProgress"
        @click="walletSignIn"
        primary
        v-if="buttonSignInVisible && false"
        small
      >
        Signin to wallet
      </Button>

      <Button
        :disabled="state.requestInProgress"
        @click="walletSignOut"
        primary
        v-if="buttonSignOutVisible && false"
        small
      >
        Signout from wallet
      </Button>
    </div>

    <NftStatusPanel
      :blockchain="currentBlockchain?.title"
      :networkType="currentNetworkTypeTitle"
      :accountAddress="currentAccountAddress"
      tokenStatus="none"
      :showTokenStatus="false"
    />

    <div class="mint-note" v-if="!!currentNetwork && !isTestnet">
      <div class="mint-note__icon-wrapper">
        <v-icon class="mint-note__icon" :size="12">
          mdi-exclamation-thick
        </v-icon>
      </div>
      <div class="mint-note__text">
        Mint NFT in testnet mode to check if everything is ok.
      </div>
    </div>

    <div class="properties__wrapper">
      <TextInput v-model="createdBy" label="Created by" type="text" />

      <TextArea v-model="description" label="Description" />

      <NftPropertiesList v-model="nftPropertiesList" />
    </div>

    <div class="controls__wrapper">
      <Button
        :disabled="state.requestInProgress || !currentNetwork"
        @click="mint"
        dark
      >
        {{ mintButtonTitle }}
      </Button>
    </div>

    <BlockchainsDialog
      ref="blockchainsDlg"
      :items="[
        {value: 'POLYGON', title: 'Polygon', icon: require('@/images/blockchains/polygon.png')},
        {value: 'NEAR', title: 'NEAR', icon: require('@/images/blockchains/near.png')}
      ]"
    />
  </div>
</template>

<style scoped lang="scss">
.mint-note {
  margin-top: 12px;
  display: flex;
  flex-direction: row;
}

.mint-note__text {
  font: 400 12px Inter;
  color: #2AD746;
  margin-left: 10px;
}

.mint-note__icon {
  color: black;
  position: absolute;
  top: 1px;
  left: 2px;
}

.mint-note__icon-wrapper {
  border-radius: 50%;
  background: #2AD746;;
  min-width: 16px;
  height: 16px;
  position: relative;
}

.properties__wrapper {
  margin-top: 20px;
}

.controls__wrapper {
  margin-top: 20px;
}

.nft-properties-list {
  margin-top: 18px;
}

.blockchain-switch-wrapper {
  display: flex;
  flex-direction: row;
}

.blockchain-switch {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  margin-bottom: 15px;
  color: #FFFFFF;
}

.blockchain-switch:hover .blockchain-switch__title,
.blockchain-switch:hover .blockchain-switch__icon {
  color: #00D7FF;
}
.blockchain-switch__title {
  font: 500 14px 'Poppins';
}

.blockchain-switch__icon {
  margin-left: 10px;
  margin-top: 1px;
}

.wallet-logout {
  margin-left: auto;
  cursor: pointer;
  font: 500 14px 'Poppins';
  color: #F14953;

  &:hover {
    text-decoration: underline;
  }
}
</style>
