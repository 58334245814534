<script setup>
import { ref, computed } from 'vue'

const props = defineProps({
  items: Array,
  position: {
    type: String,
    default: 'left',
  },
  width: {
    type: String,
    default: 'auto',
  },
})

const menu = ref(null)
const positionLeft = ref(0)
const positionTop = ref(0)
const clickParams = ref(null)
const isOpen = ref(false)

const catchClick = (event) => {
  close()
}

function show(left, top, params) {
  positionLeft.value = left
  positionTop.value = top
  clickParams.value = params

  isOpen.value = true
  window.addEventListener('click', catchClick)
}

function close() {
  isOpen.value = false
  window.removeEventListener('click', catchClick)
}

const wrapperStyle = computed(() => {
  const style = {
    left: `${positionLeft.value}px`,
    top: `${positionTop.value}px`,
  }

  if (props.position === 'right') {
    style.transform = 'translateX(-100%)'
  }

  style.width = props.width

  return style
})

defineExpose({ show })
</script>

<template>
  <Teleport to="body">
    <transition name="fade" appear>
      <div class="popup-menu__wrapper"
        v-if="isOpen"
        ref="menu"
        :style="wrapperStyle">
        <div
          class="sub-menu-item"
          :class="{ item__separator: item.separator, 'sub-menu-item--no-icon': !item.icon }"
          v-for="item, index in items"
          :key="index"
        >
          <v-icon class="icon" v-if="item.icon">{{ item.icon }}</v-icon>
          <router-link
            :to="{ name: item.routeName }"
            v-if="item.routeName"
            @click="close()"
          >
            {{ item.title }}
          </router-link>
          <a
            href="#"
            @click.stop.prevent="item.click(item, clickParams); close()"
            v-if="item.click"
          >
            {{ item.title }}
          </a>
        </div>

      </div>
    </transition>
  </Teleport>
</template>

<style scoped lang="scss">
.popup-menu__wrapper {
  position: absolute;
}

.popup-menu__wrapper {
  position: absolute;
  background: #091520;
  border: 1px solid #334656;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  align-items: stretch;
  padding: 10px;
  z-index: 200;

  display: flex;
  flex-direction: column;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s ease-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.sub-menu-item {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.sub-menu-item i {
  margin: 8px;
}

.sub-menu-item a {
  flex: 1;
  color: white;
  padding: 10px 10px 10px 12px;
  text-decoration: none;
  display: block;
  font: normal 14px/17px 'Inter';
  white-space: nowrap;
}

.sub-menu-item--no-icon a {
    padding-left: 10px;
  }

.sub-menu-item:hover {
  background-color: #273846;
  border-radius: 4px;
}

.item__separator {
  position: relative;
  margin-bottom: 9px;

  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 1px;
    background: white;
    bottom: -5px;
    opacity: 0.3;
  }
}

</style>
