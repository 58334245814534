<script setup>
import { ref, computed, onMounted, onBeforeMount, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter, onBeforeRouteLeave } from 'vue-router'
import { useVuex } from '@vueblocks/vue-use-vuex'

import { useNotification } from '@kyvg/vue3-notification'

import Dialog from '@/components/common/dialogs/Dialog'
import PrivateSpace from '@/views/creator/PrivateSpace.vue'
import * as DialogButtons from '@/components/common/dialogs/DialogButtons'
import entityTypes from '@/helpers/entityTypes'
import creatorItemViews from '@/helpers/creatorItemViews'
import { processError } from '@/helpers/errors'

import {
  Button,
  TabNav,
  Tabs,
  Tab,
  TextInput,
  RichTextEditor,
  FileInput,
  // ContentFilter,
  // ViewModeSwitcher,
  ContentToolbar,
  ModelViewer,
  Checkbox,
  NftStudio,
  LoadingScreenPreview,
  ExportDialog,
  Slider,
  Tooltip,
  LoadingIndicator,
  AnalyticsPanel,
  SaveConfirmationDialog,
} from '@/components/creator'

import { AvatarCards } from '@/components/worlds/avatars'
import { ContentItems } from '@/components/worlds/content'

// import creatorItemViews from '@/helpers/creatorItemViews'
// import creatorItemTypes from '@/helpers/creatorItemTypes'

import { deepClone, getContentUrl, getLoadingState } from '@/utils/common'
import { Space, SpaceTemplate, CustomDomain } from '@/helpers/api/models'
import { standardRules } from '@/helpers/validationRules'
import CustomDomainBrief from '@/components/custom_domains/CustomDomainBrief'
import DefaultAvatarSetPanel from '@/components/worlds/avatars/DefaultAvatarSetPanel.vue'
import { isEqual } from 'lodash-es'
import { isAvailable } from '@/helpers/billingFeatures'

const { notify } = useNotification()

const state = ref(getLoadingState())
const stateSa = ref(getLoadingState())

const stateAll = computed(() => {
  return {
    requestInProgress: state.value.requestInProgress || stateSa.value.requestInProgress || contentItems.value?.state.requestInProgress || avatarCards.value?.state.requestInProgress || analyticsPanel.value?.state.requestInProgress,
    isLoading: state.value.isLoading || stateSa.value.isLoading || contentItems.value?.state.isLoading || avatarCards.value?.state.isLoading || analyticsPanel.value?.state.isLoading,
    isUpdating: state.value.isUpdating || stateSa.value.isUpdating || contentItems.value?.state.isUpdating || avatarCards.value?.state.isUpdating || analyticsPanel.value?.state.isUpdating,
  }
})

const exportDialog = ref()
const saveConfirmationDialog = ref()

const store = useStore()
const { useActions } = useVuex(null, store)
const {
  apiSpacesGet,
  apiSpaceTemplatesGet,
  apiSpacesUpdate,
  apiSpaceTemplatesUpdate,
  apiSpacesDelete,
  apiSpaceTemplatesDelete,
  setTitle,
  apiGetSaToken,
} = useActions([
  'apiSpacesGet',
  'apiSpaceTemplatesGet',
  'apiSpacesUpdate',
  'apiSpaceTemplatesUpdate',
  'apiSpacesDelete',
  'apiSpaceTemplatesDelete',
  'setTitle',
  'apiGetSaToken',
])
const route = useRoute()
const router = useRouter()

const tabItems = ref([])
const tab = ref('')
const tabPreviews = ref('')
const nftStudio = ref(null)
const toolBar = ref(null)
const openWorldDialog = ref(null)
const deleteWorldDialog = ref(null)
const customDomainBrief = ref(null)
const contentItems = ref(null)
const avatarCards = ref(null)
const analyticsPanel = ref(null)
const defaultAvatarsSetPanelVisible = ref(false)

const form = ref(null)
const isBaseFormValid = ref(false)
const fileInputGlb = ref(null)
const fileInputCustomLoadingBackground = ref(null)
const isFormValid = computed(() => {
  return isBaseFormValid.value && fileInputGlb.value?.valid
})
const toolTip = ref()
const contentFooter = ref(null)

const contentColumnFirst = ref(null)
const contentColumnSecond = ref(null)

const saToken = ref(null)
const space = ref(deepClone(Space))
const spaceTemplate = ref(deepClone(SpaceTemplate))
const spaceOrigin = ref(null)
const spaceTemplateOrigin = ref(null)
const spaceId = route.params.id
const customDomain = ref(deepClone(CustomDomain))
const isSpaceLoaded = computed(() => !!space.value?.id)

const maxUsersPerRoomMaximum = 30
const maxUsersPerRoom = ref(maxUsersPerRoomMaximum)
const maxUsersPerRoomChanged = ref(false)

const openWorldButton = computed(
  () => toolBar.value?.$refs.openWorldToolButton[0]
)
const spaceUrl = computed(() =>
  customDomain.value?.state === 'LINKED'
    ? `https://${customDomain.value?.id}`
    : `${process.env.VUE_APP_SPACES_URL}/${space.value?.id}`
)
const updateSpace = (updatedSpace) => {
  space.value = updatedSpace
}
function isThemeEnabled () {
  return !!space.value?.theme.loader.background_image
}
const themeLoaderBackground = ref()

const loadingPreviewDefaultImage = require('@/images/world-loader-bg.jpg')
const loadingPreviewImage = computed(() => {
  return themeLoaderBackground.value
    ? getContentUrl(themeLoaderBackground.value)
    : loadingPreviewDefaultImage
})

const isSpaceModified = computed(() => {
  return !isEqual(space.value, spaceOrigin.value || !isEqual(spaceTemplate.value, spaceTemplateOrigin.value))
})

const advancedIsAvailable = computed(() => {
  return isAvailable('WORLDS_ADVANCED_SETTINGS_ENABLED')
})

const customLoaderIsAvailable = computed(() => {
  return isAvailable('WORLDS_LOADER_CUSTOMIZATION_ENABLED')
})

const analyticsIsAvailable = computed(() => {
  return isAvailable('ANALYTICS_ENABLED')
})

function close () {
  router.push({
    name: 'Worlds',
  })
}

async function loadWorld () {
  try {
    state.value.requestInProgress = true
    state.value.isLoading = true

    space.value = (
      await apiSpacesGet({ id: spaceId })
    ).content

    themeLoaderBackground.value = space.value.theme.loader.background_image

    spaceTemplate.value = (
      await apiSpaceTemplatesGet({
        id: space.value.template.id,
      })
    ).content

    spaceOrigin.value = deepClone(space.value)
    spaceTemplateOrigin.value = deepClone(spaceTemplate.value)
    maxUsersPerRoom.value = space.value.max_users_per_room || maxUsersPerRoomMaximum

    setTitle(`${space.value.name} | World settings`)
  } catch (error) {
    processError(error)
  } finally {
    state.value.requestInProgress = false
    state.value.isLoading = false
  }
}

async function updateWorld () {
  try {
    state.value.requestInProgress = true
    state.value.isUpdating = true

    space.value.theme.loader.enabled = !!themeLoaderBackground.value
    space.value.theme.loader.background_image = themeLoaderBackground.value
    space.value.theme.enabled = isThemeEnabled()

    if (maxUsersPerRoomChanged.value) {
      space.value.max_users_per_room = maxUsersPerRoom.value
    }

    await Promise.all([
      apiSpacesUpdate({ data: space.value }),
      apiSpaceTemplatesUpdate({
        data: spaceTemplate.value,
      }),
    ])

    spaceOrigin.value = deepClone(space.value)
    spaceTemplateOrigin.value = deepClone(spaceTemplate.value)

    notify({
      type: 'success',
      text: 'Your world has been updated',
    })

    setTitle(`${space.value.name} | World settings`)

    return true
  } catch (error) {
    processError(error)

    return false
  } finally {
    state.value.requestInProgress = false
    state.value.isUpdating = false
  }
}

async function deleteWorld () {
  try {
    state.value.requestInProgress = true
    state.value.isDeleting = true

    await Promise.all([
      apiSpacesDelete({ id: space.value.id }),
      apiSpaceTemplatesDelete({
        id: spaceTemplate.value.id,
      }),
    ])
    close()
  } catch (error) {
    processError(error)
  } finally {
    state.value.requestInProgress = false
    state.value.isDeleting = false
  }
}

async function showExportDialog () {
  exportDialog.value.show()
}

async function refreshSaToken () {
  try {
    stateSa.value.requestInProgress = true
    stateSa.value.isLoading = true

    saToken.value = (await apiGetSaToken()).content.id
  } catch (error) {
    processError(error)
  } finally {
    stateSa.value.requestInProgress = false
    stateSa.value.isLoading = false
  }
}

function maxUsersTitleHovered (hovered, target) {
  if (hovered) {
    const rect = target.getBoundingClientRect()

    const toolTipLeft = window.pageXOffset + rect.left + rect.width / 2 - 1
    const toolTipTop = window.pageYOffset + rect.top + rect.height + 5
    toolTip.value.show(toolTipLeft, toolTipTop)
  } else {
    toolTip.value.hide()
  }
}

watch(tab, async (newTab) => {
  if (['Avatars', 'Content', 'Analytics'].includes(newTab)) {
    contentColumnFirst.value.classList.add('content__column-full-width')
    contentColumnSecond.value.classList.add('content__column-hidden')
    contentFooter.value.classList.add('content__footer-hidden')
  } else {
    contentColumnFirst.value.classList.remove('content__column-full-width')
    contentColumnSecond.value.classList.remove('content__column-hidden')
    contentFooter.value.classList.remove('content__footer-hidden')
  }

  if (newTab !== 'NFT') {
    return
  }

  const fromSignin = route.query.signin === '1'

  await new Promise((resolve) => {
    setTimeout(() => {
      resolve(true)
    }, 100)
  })

  if (!fromSignin) {
    nftStudio.value.onVisible()
  }
})

onMounted(async () => {
  await Promise.all([loadWorld(), refreshSaToken()])
  form.value?.validate()
})

onBeforeMount(async () => {
  tabItems.value = ['Main', 'Advanced settings', 'Avatars', 'Content']

  if (process.env.VUE_APP_STAGE !== 'prod') {
    tabItems.value.push('NFT')
  }

  if (route.query.signin === '1') {
    setTimeout(async () => {
      tab.value = 'NFT'
    }, 100)
  }

  tabItems.value.push({
    value: 'Analytics',
    icon: analyticsIsAvailable.value ? '' : 'mdi-lock-outline',
    disabled: !analyticsIsAvailable.value,
    color: analyticsIsAvailable.value ? '' : '#ACC2D2',
  })
})

const openWorldClicked = async (event) => {
  openWorldDialog.value.show()
}

const deleteWorldClicked = async () => {
  const result = await deleteWorldDialog.value.show()
  if (result === 'delete') {
    await deleteWorld()
  }
}

function defaultAvatarsSetPanelClose (result) {
  defaultAvatarsSetPanelVisible.value = false

  if (result !== undefined) {
    space.value.default_avatars_disabled = result
    updateWorld()
  }
}

onBeforeRouteLeave(async (to, from, next) => {
  if (!isSpaceLoaded.value || !isSpaceModified.value) {
    next()
    return
  }

  const res = await saveConfirmationDialog.value.show()
  if (res === 'ok') {
    // user chose to lose changes
    next()
  } else {
    // user chose to stay on the page
    next(false)
  }
})

async function tabUpdateModelValue (newValue) {
  if (!['Main', 'Advanced settings'].includes(newValue) && isSpaceModified.value && isSpaceLoaded.value) {
    // we leave Main or Advanced settings tab and space is modified
    const res = await saveConfirmationDialog.value.show()
    if (res === 'ok') {
      // user chose lose changes
      space.value = deepClone(spaceOrigin.value)
      spaceTemplate.value = deepClone(spaceTemplateOrigin.value)
      tab.value = newValue
    }
  } else {
    tab.value = newValue
  }
}
</script>

<template>
  <div>
    <div class="g-title-wrapper">
      <h1 class="g-title">Your World</h1>

      <ContentToolbar
        ref="toolBar"
        :items="[
          {
            text: 'Open world',
            icon: 'mdi-link-variant',
            target: '_blank',
            onClick: openWorldClicked,
            ref: 'openWorldToolButton',
          },
        ]"
      />
    </div>

    <div class="g-content g-shrink">
      <div class="g-content__header">
        <div class="g-tabs__wrapper">
          <TabNav
            :items="tabItems"
            v-bind:model-value="tab"
            @update:model-value="tabUpdateModelValue"
            urlParam="tab"
          >
            <!--<div class="g-content-toolbar_wrapper" v-if="tab === 'Content'">
              <ContentFilter
                :default-filter="creatorItemTypes.all"
              ></ContentFilter>
              <ViewModeSwitcher
                :default-view="creatorItemViews.cards"
              ></ViewModeSwitcher>
            </div>
            -->
          </TabNav>
        </div>
      </div>
      <div class="g-content__inner g-shrink">
        <div class="g-content__columns-wrapper g-shrink">

          <LoadingIndicator :state="stateAll" all />

          <div ref="contentColumnFirst" class="g-content__column-first">
            <v-form
              v-model="isBaseFormValid"
              :loading="state.requestInProgress"
              class="g-shrink form"
              ref="form"
              @submit.prevent=""
            >
              <Tabs v-model="tab">
                <Tab value="Main">
                  <div class="g-content__columns-wrapper g-shrink">
                    <div class="g-content__column-first">
                      <div class="g-section-title">BASIC INFO</div>
                      <TextInput
                        v-model="space.name"
                        name="name"
                        type="text"
                        label="World name"
                        :rules="[standardRules.required]"
                        autofocus
                      />

                      <FileInput
                        ref="fileInputGlb"
                        v-model="spaceTemplate.glbmodel"
                        :extensions="['.glb', '.gltf']"
                        placeholder="World file"
                        required
                        :validator="{}"
                      />

                      <div class="g-section-title">DETAILS</div>
                      <RichTextEditor
                        v-model="space.description"
                        label="Description"
                        class="input-description"
                        maxHeight="300px"
                      />

                      <FileInput
                        v-model="space.preview"
                        :extensions="['.jpg', '.jpeg', '.png', '.gif']"
                        placeholder="Preview image"
                        :validator="{}"
                        class="file-input-preview"
                      />

                      <FileInput
                        v-model="space.environment.sound"
                        :extensions="['.mp3']"
                        placeholder="Ambient sound (mp3)"
                        :validator="{}"
                      />

                      <FileInput
                        v-model="themeLoaderBackground"
                        ref="fileInputCustomLoadingBackground"
                        :extensions="['.jpg', '.png']"
                        placeholder="Custom loading background"
                        :validator="{}"
                        description="JPG, PNG | Rec. size is 1920x1080 px | 16:9 ratio"
                        :lock="!customLoaderIsAvailable"
                      />
                    </div>
                  </div>
                </Tab>

                <Tab value="Advanced settings">
                  <div class="g-content__columns-wrapper g-shrink">
                    <div class="g-content__column-first">
                      <div class="g-section">
                        <div class="g-section-title">Mobile support</div>

                        <FileInput
                          v-model="spaceTemplate.glbmodel_mobile"
                          :extensions="['.glb', '.gltf']"
                          placeholder="World for Mobile version"
                          :validator="{}"
                        />

                        <Checkbox
                          title="Hide slots on mobile"
                          v-model="spaceTemplate.hide_slots_on_mobile"
                        />

                        <Checkbox
                          title="Disable custom avatar on mobile"
                          v-model="space.mobile_disable_avatar"
                        />

                        <div class="g-section">
                          <div class="section-limitations__inner" :class="{ 'need-upgrade': !advancedIsAvailable }">
                            <div class="g-section-title g-section-title-limitations">
                              <span>Limitations</span>
                              <v-icon v-if="!advancedIsAvailable" size="21" class="icon-lock">mdi-lock-outline</v-icon>
                              <router-link v-if="!advancedIsAvailable" class="upgrade-link" :to="{ name: 'Profile', query: {tab: 'Billing'} }">Upgrade</router-link>
                            </div>

                            <div class="max-users__wrapper">
                              <div class="max-users-title__wrapper">
                                <span class="max-users-title__text" :class="{ disabled: !advancedIsAvailable }">Max users per room</span>
                                <v-icon
                                  :size="22"
                                  class="max-users-title__icon"
                                  @mouseover="$event => maxUsersTitleHovered(true, $event.target)"
                                  @mouseleave="$event => maxUsersTitleHovered(false, $event.target)"
                                >
                                  mdi-information-outline
                                </v-icon>
                              </div>

                              <Slider
                                :minValue="1"
                                :maxValue="30"
                                v-model="maxUsersPerRoom"
                                class="max-users__slider"
                                @update:model-value="maxUsersPerRoomChanged = true"
                                :disabled="!advancedIsAvailable"
                              />

                            </div>

                            <Checkbox
                              title="Disable voice chat"
                              v-model="space.voicechat_disabled"
                              :disabled="!advancedIsAvailable"
                            />

                            <Checkbox
                              title="Disable custom nickname"
                              v-model="space.custom_nicknames_disabled"
                              :disabled="!advancedIsAvailable"
                            />
                          </div>
                        </div>

                        <div class="g-section">
                          <Button
                            class="g-controls__control"
                            @click="showExportDialog"
                            small
                          >
                            Export World
                          </Button>
                        </div>
                        <PrivateSpace
                          :space="space"
                          @update:space="updateSpace"
                          />

                      </div>
                    </div>
                  </div>
                </Tab>

                <Tab value="Avatars">
                  <AvatarCards
                    :spaceRef="{ id: spaceId, name: space.name }"
                    ref="avatarCards"
                    v-if="space"
                    returnTab="Avatars"
                    :showDefaultSet="true"
                    v-show="!defaultAvatarsSetPanelVisible"
                    @defaultAvatarsSetClicked="defaultAvatarsSetPanelVisible = true"
                  />

                  <DefaultAvatarSetPanel
                    v-if="defaultAvatarsSetPanelVisible"
                    @close="defaultAvatarsSetPanelClose"
                    :initDefaultAvatarsDisabled="space.default_avatars_disabled && avatarCards?.avatars.length > 0"
                    :saveDisabled="!isFormValid"
                    :editable="avatarCards?.avatars.length > 0"
                  />
                </Tab>

                <Tab value="Content">
                  <ContentItems
                    :defaultView="creatorItemViews.cards"
                    :spaceId="spaceId"
                    ref="contentItems"
                    class="g-shrink"
                    />
                </Tab>

                <Tab value="NFT">
                  <div class="g-content__columns-wrapper g-shrink">
                    <div class="g-content__column-first">
                      <NftStudio
                        ref="nftStudio"
                        @needClose="tab = 'Main'"
                        :entityType="entityTypes.space.name"
                        :entity="space"
                      />
                    </div>
                  </div>
                </Tab>

                <Tab value="Analytics" class="tab-analytics" v-if="analyticsIsAvailable">
                  <AnalyticsPanel
                    :entityId="spaceId"
                    :entityType="entityTypes.space.name"
                    ref="analyticsPanel"
                  />
                </Tab>
              </Tabs>
            </v-form>
          </div>
          <div ref="contentColumnSecond" class="g-content__column-second">
            <TabNav :items="['Preview', 'Loading screen']" v-model="tabPreviews" ghost>
            </TabNav>
            <Tabs v-model="tabPreviews">
              <Tab value="Preview">
                <ModelViewer
                  :src="spaceTemplate.glbmodel"
                  :poster="space.preview"
                  type="GLB"
                />
              </Tab>
              <Tab value="Loading screen">
                <div class="g-content-preview-wrapper">
                  <LoadingScreenPreview
                    :percent="65"
                    pattern="{%}% Loading World"
                    :image="loadingPreviewImage"
                  />
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>

        <div class="g-content__footer" ref="contentFooter">
          <div class="g-controls__wrapper">
            <div class="g-controls__col-first">
              <Button
                class="g-controls__control"
                @click="close"
                :disabled="state.requestInProgress"
              >
                Close
              </Button>
              <Button
                class="g-controls__control"
                @click="updateWorld"
                primary
                :disabled="state.requestInProgress || !isFormValid"
                :loading="state.isUpdating"
              >
                Update
              </Button>
            </div>
            <div class="g-controls__col-last">
              <Button
                class="g-controls__control"
                @click="deleteWorldClicked"
                :disabled="state.requestInProgress"
                :loading="state.isDeleting"
                alert
              >
                Delete
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Dialog
      class="open-world-dialog"
      ref="openWorldDialog"
      :blurred="false"
      :buttons="[]"
      :boundElement="openWorldButton"
      boundType="bottom right"
      :boundOffsetX="15"
      :boundOffsetY="14"
    >
      <div class="open-world-dialog-link-box">
        <div class="url">
          {{ `${spaceUrl}` }}
        </div>
        <a
          class="link"
          :href="`${spaceUrl}?sa-token=${saToken}`"
          target="_blank"
          @click="refreshSaToken()"
        >
          <v-icon>mdi-open-in-new</v-icon>
        </a>
      </div>
      <CustomDomainBrief
        ref="customDomainBrief"
        class="custom-domain-brief"
        linkType="SPACE"
        :linkId="space.id"
        v-model="customDomain"
      />
    </Dialog>

    <Dialog
      title="Delete World?"
      ref="deleteWorldDialog"
      :buttons="[DialogButtons.Cancel, DialogButtons.DeletePrimary]"
    >
      <div class="g-text-center">
        The World will be deleted with all the information.<br />
        All Content is saved in Storage
      </div>
    </Dialog>

    <ExportDialog
      title="Select export option"
      ref="exportDialog"
      entityType="space"
      :entity="space"
    />

    <SaveConfirmationDialog
      ref="saveConfirmationDialog"
    />

    <Tooltip ref="toolTip">
      <div class="max-width-tooltip__content">Use to create different user scenarios and improve the world stability</div>
    </Tooltip>
  </div>
</template>

<style lang="scss" scoped>
.g-content__columns-wrapper {
  flex: 1;
}

.g-controls__col-first,
.g-controls__col-last {
  flex: 1;
}

.g-controls__col-last {
  align-items: flex-end;
}

.input-description {
  margin-top: 13px;
}

.content-toolbar-wrapper {
  margin-left: auto;
}

.open-world-dialog > :deep(.wrapper) {
  width: 391px;
}

.custom-domain-brief {
  margin-top: 30px;
}

.open-world-dialog-link-box {
  display: flex;
  flex-direction: row;
  flex: 1;

  align-items: center;

  border: #334656 solid 1px;
  border-radius: 8px;
  padding: 8px;

  color: white;

  & > .url {
    flex: 1;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
  }
  & > .link {
    &,
    &:visited,
    &:active,
    &:hover {
      margin-top: -4px;
      margin-left: 8px;
      font-size: 18px;
      text-decoration: none;
      color: white;
    }
  }
}

.file-input-preview {
  margin-top: 23px;
}

.form {
  display: flex;
  flex: 1;
  height: 100%;
}

.max-users-title__text {
  font: 400 14px 'Inter';

  &.disabled {
    color: #667F92;
  }
}

.max-users-title__wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.max-users-title__icon {
  color: #ACC2D2;
  margin-left: 6px;
}

.max-users__slider {
  margin-top: 4px;
}

.max-width-tooltip__content {
  max-width: 230px;
}

.section-limitations__inner.need-upgrade {
  border: 1px solid #667F92;
  border-radius: 5px;
  padding: 14px 12px;
}

.icon-lock {
  color: #00D7FF;
  margin-left: 6px;
}

.upgrade-link {
  font: 400 14px 'Poppins';
  margin-left: auto;
  color: #00D7FF;
  text-transform: none;
}

.g-section-title-limitations {
  margin-bottom: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.content__column-full-width {
  width: 100%;
  padding-right: 0;
}

.content__column-hidden,
.content__footer-hidden {
  display: none;
}

.tab-analytics {
  overflow: auto;
}

.avatars-cards__wrapper {
  height: 100%;
}

.content-items__wrapper {
  flex: 1;
}

.toggle-switch-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.toggle-switch-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align items to the left */
  padding-left: 20px; /* Move the toggle switch to the right */
}

.toggle-label {
  margin-top: 4px; /* Add space between the switch and the label text */
  padding-right: 10px; /* Align the label text with the toggle switch */
  color: #00D7FF;
}

.toggle-label.disabled {
  color: #909EAF;
}

.toggle-text {
  font: 400 14px 'Inter';
}
</style>
