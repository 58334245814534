<script setup>
import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useVuex } from '@vueblocks/vue-use-vuex'
import { useRouter } from 'vue-router'
import { Profile, ProfileAccessRecord, ProfileRoles } from '@/helpers/api/models'
import { deepClone, firstLetterToUpperCase, apiCallWithState, capitalize } from '@/utils/common'
import { notify } from '@kyvg/vue3-notification'
import isEqual from 'lodash-es/isEqual'
import { standardRules } from '@/helpers/validationRules'
import Dialog from '@/components/common/dialogs/Dialog'
import * as DialogButtons from '@/components/common/dialogs/DialogButtons'
import ItemsTable from '@/components/ItemsTable.vue'
import ProvideAccessDialog from '@/components/creator/ProvideAccessDialog.vue'
import CreateBtn from '@/components/btns/CreateBtn.vue'

import {
  PaymentDialog,
  BillingDetailsDialog,
} from '@/components/creator/payments'

import { EmailDialog, InvoicesDialog, ChangePlanDialog, ChangeBillingPeriodDialog } from '@/components/creator/dialogs'

import { Button, TabNav, Tabs, Tab, TextInput, InfoAction, LoadingIndicator } from '@/components/creator'
import { processError } from '@/helpers/errors'

const router = useRouter()
const store = useStore()

const state = ref({
  requestInProgress: false,
  isLoading: false,
  isUpdating: false,
  isDeleting: false,
})

const { useActions } = useVuex(null, store)
const {
  apiProfilesGet,
  apiProfilesUpdate,
  apiProfilesDelete,
  apiPaymentMethodGet,
  apiBillingDetailsGet,
  apiBillingDetailsSet,
  apiUserSubscriptionInfoGet,
} = useActions([
  'apiProfilesGet',
  'apiProfilesUpdate',
  'apiProfilesDelete',
  'apiPaymentMethodGet',
  'apiBillingDetailsGet',
  'apiBillingDetailsSet',
  'apiUserSubscriptionInfoGet',
])

const currentProfile = computed(() => store.state.auth.profile)
const user = computed(() => store.state.auth.user)
const currentSubscriptionTitle = computed(() => subscriptionUpdating.value ? 'Updating... ' : 'Current plan ')

const profile = ref(deepClone(Profile))
const profileSrc = ref(deepClone(Profile))
const profileEdited = computed(() => !isEqual(profile.value, profileSrc.value))

const tab = ref(null)
const formPersonalValid = ref(null)
const deleteProfileDialog = ref(null)
const deleteAccessDialog = ref(null)
const provideAccessDialog = ref(null)

const userSubscriptionInfo = ref(null)
const userPaymentMethod = ref(null)
const paymentMethodUpdating = ref(false)
const billingPeriodUpdating = ref(false)
const subscriptionUpdating = ref(false)
const billingName = ref(null)
const billingEmail = ref(null)
const billingAddress = ref(null)

const paymentDialog = ref(null)
const billingDetailsDialog = ref(null)
const billingEmailDialog = ref(null)
const invoicesDialog = ref(null)
const changeSubscriptionDialog = ref(null)
const changeBillingPeriodDialog = ref(null)

const accessListColumns = [
  {
    sortKey: 'fullName',
    title: 'Name',
    field: 'fullName',
    type: 'text',
  },
  {
    sortKey: 'email',
    title: 'Email',
    field: 'email',
    type: 'text',
  },
  {
    sortKey: 'role',
    title: 'Role',
    field: 'role',
    type: 'text',
  },
  {
    type: 'buttons',
    width: '80px',
    options: {
      buttons: [
        {
          name: 'edit',
          icon: 'mdi-pencil-outline',
          theme: 'primary',
        },
        {
          name: 'delete',
          icon: 'mdi-trash-can-outline',
          theme: 'alert',
        },
      ],
    },
  },
]

const deletable = computed(() =>
  // this profile is not default for any user
  // and i'm owner
  !profile.value.is_personal_for?.id &&
    myAclRecord.value?.role === ProfileRoles.OWNER
)

const myAclRecord = computed((x) =>
  profile.value.acl.find(x => x.user.email.toLowerCase() === user.value.email.toLowerCase())
)

const accessList = computed(() => {
  const result = []
  for (const item of profile.value.acl) {
    result.push({ fullName: item.user.full_name, email: item.user.email, role: item.role, item: item })
  }
  return result
})

const paymentMethodInfoActionTitle = computed(() => {
  if (!userPaymentMethod.value?.id) {
    return ''
  }

  const paymentMethodType = firstLetterToUpperCase(userPaymentMethod.value.type || '')
  return paymentMethodUpdating.value
    ? 'Updating payment method...'
    : `${paymentMethodType} ending ${userPaymentMethod.value.last4}`
})

const billingPeriodInfoActionTitle = computed(() => {
  return billingPeriodUpdating.value
    ? 'Updating billing period...'
    : capitalize(billingPeriod.value)
})

const billingAddressTitle = computed(() => {
  const address = billingAddress.value
  if (!address) {
    return ''
  }
  return `${address.postal_code || ''} ${address.country || ''} ${address.state || ''} ${address.city || ''} ${address.line1 || ''} ${address.line2 || ''}`
})

const billingPeriod = computed(() => {
  return firstLetterToUpperCase(userSubscriptionInfo.value.billing_period || '')
})

const deleteProfileClicked = async () => {
  const result = await deleteProfileDialog.value.show()
  if (result === 'delete') {
    try {
      await apiProfilesDelete({ id: currentProfile.value.id })
      router.go()
    } catch (e) {
      processError(e)
    }
  }
}

function deleteAccess (item) {
  profile.value.acl = profile.value.acl.filter((x) => x.user.email !== item.email)
}

function editAccess (email, newVal) {
  const record = deepClone(ProfileAccessRecord)
  record.user.email = newVal.email
  record.user.full_name = newVal.fullName
  record.role = newVal.role

  const index = profile.value.acl.findIndex((x) => x.user.email === email)
  profile.value.acl[index] = record
}

async function loadProfile () {
  const params = {
    id: currentProfile.value.id,
  }

  const apiResult = await apiCallWithState(apiProfilesGet, params, state, 'isLoading')

  profile.value = apiResult.content
  profileSrc.value = deepClone(profile.value)
}

async function saveProfile () {
  try {
    state.value.requestInProgress = true
    state.value.isUpdating = true

    profile.value = (await apiProfilesUpdate(
      { data: profile.value })
    ).content

    profileSrc.value = deepClone(profile.value)

    notify({
      type: 'success',
      text: 'Profile settings updated',
    })
  } catch (e) {
    processError(e)
  } finally {
    state.value.requestInProgress = false
    state.value.isUpdating = false
  }
}

function resetProfileForm () {
  profile.value = deepClone(profileSrc.value)
}

async function accessButtonsClicked (button, item) {
  if (button.name === 'delete') {
    if ((await deleteAccessDialog.value.show()) === 'delete') {
      deleteAccess(item)
    }
  } else if (button.name === 'edit') {
    const emailSrc = item.email
    const resultEdit = (await provideAccessDialog.value.show(accessList.value, item.email, item.role))
    if (resultEdit) {
      editAccess(emailSrc, resultEdit)
    }
  }
}

async function provideAccessClicked () {
  const result = (await provideAccessDialog.value.show(accessList.value, '', ''))
  if (result) {
    const record = deepClone(ProfileAccessRecord)
    record.user.email = result.email
    record.user.full_name = result.fullName
    record.role = result.role

    profile.value.acl.push(record)
  }
}

async function addUpdatePaymentMethod () {
  const dialogResult = await paymentDialog.value.show()
  if (dialogResult?.result) {
    paymentMethodUpdating.value = true
    setTimeout(() => {
      loadUserSubscriptionInfo()
      paymentMethodUpdating.value = false
    }, 5000)
  } else if (dialogResult?.error) {
    notify({
      type: 'error',
      text: dialogResult.error,
    })
  }
}

async function billingDetailsEditClicked () {
  const dialogResult = await billingDetailsDialog.value.show(billingName.value, billingAddress.value)
  if (dialogResult?.result) {
    const data = {
      name: dialogResult.name,
      email: billingEmail.value,
      address: JSON.stringify(dialogResult.address),
    }
    await apiBillingDetailsSet({ data: data })
    await loadUserBillingDetails()
  } else if (dialogResult?.error) {
    notify({
      type: 'error',
      text: dialogResult.error,
    })
  }
}

async function billingEmailEditClicked () {
  const dialogResult = await billingEmailDialog.value.show(billingEmail.value)
  if (dialogResult?.result) {
    const data = {
      name: billingName.value,
      email: dialogResult.email,
      address: JSON.stringify(billingAddress.value),
    }
    await apiBillingDetailsSet({ data: data })
    await loadUserBillingDetails()
  } else if (dialogResult?.error) {
    console.error(dialogResult.error)
    notify({
      type: 'error',
      text: dialogResult.error,
    })
  }
}

async function invoicesClicked () {
  await invoicesDialog.value.show()
}

async function changeSubscriptionClicked () {
  const result = (await changeSubscriptionDialog.value.show())

  if (result === 'switched') {
    subscriptionUpdating.value = true
    setTimeout(() => {
      loadUserSubscriptionInfo()
      subscriptionUpdating.value = false
    }, 5000)
  }
}

async function billingPeriodUpdateClicked () {
  if (await changeBillingPeriodDialog.value.show() === true) {
    billingPeriodUpdating.value = true
    setTimeout(() => {
      loadUserSubscriptionInfo()
      billingPeriodUpdating.value = false
    }, 5000)
  }
}

async function loadUserBillingDetails () {
  try {
    const apiResult = await apiCallWithState(apiBillingDetailsGet, null, state, 'isLoading')
    const responseBillingDetails = apiResult.content
    billingName.value = responseBillingDetails.name
    billingEmail.value = responseBillingDetails.email
    try {
      billingAddress.value = JSON.parse(responseBillingDetails.address)
    } catch (e) {
      billingAddress.value = { postal_code: '', country: '', state: '', city: '', line1: '', line2: '' }
    }
  } catch (e) {
    if (e?.statusCode !== 400) {
      processError(e)
    }
  }
}

async function loadUserSubscriptionInfo () {
  try {
    userSubscriptionInfo.value = (await apiCallWithState(apiUserSubscriptionInfoGet, null, state, 'isLoading')).content
  } catch (e) {
    processError(e)
  }
}

async function loadUserPaymentMethod () {
  try {
    userPaymentMethod.value = (await apiCallWithState(apiPaymentMethodGet, null, state, 'isLoading')).content.payment_methods[0]
  } catch (e) {
    processError(e)
  }
}

onMounted(async () => {
  try {
    loadProfile()
    loadUserSubscriptionInfo()
    loadUserPaymentMethod()
    loadUserBillingDetails()
  } catch (e) {
    processError(e)
  }
})
</script>

<template>
  <div>
    <div class="g-title-wrapper">
      <h1 class="g-title">
        {{ currentProfile?.name }}
      </h1>
    </div>
    <div class="g-content g-shrink">
      <div class="g-content__header">
        <div class="g-content-header__col-first">
          <div class="g-tabs__wrapper">
            <TabNav :items="['Profile', 'Access', 'Billing']" v-model="tab" urlParam="tab">
            </TabNav>
          </div>
        </div>
        <div class="g-content-header__col-last">
          <div class="g-content-toolbar_wrapper" v-if="tab === 'Access'">
            <CreateBtn @click="provideAccessClicked">
              Add user
            </CreateBtn>
          </div>
        </div>
      </div>
      <div class="g-content__inner g-shrink">
        <LoadingIndicator :state="state" all />
        <Tabs v-model="tab">
          <Tab value="Profile">
            <div class="g-content__columns-wrapper g-shrink">
              <div class="g-content__column-first">
                <v-form
                  class="form-personal"
                  autocomplete="off"
                  v-model="formPersonalValid"
                >
                  <TextInput
                    v-model="profile.name"
                    type="text"
                    label="Name"
                    :rules="[standardRules.required]"
                    autofocus
                  />
                </v-form>
              </div>

              <div class="g-content__column-second">
                <div class="subscription-details__wrapper">
                  <div class="subscription-details__title">Plan details</div>
                  <div class="subscription-details__description">
                    Your current plan is {{ userSubscriptionInfo?.billing_plan.name }}
                  </div>

                </div>
              </div>
            </div>
          </Tab>

          <Tab value="Access">
            <ItemsTable
              :items="accessList"
              :columns="accessListColumns"
              @buttonsClick="accessButtonsClicked"
            />
          </Tab>

          <Tab value="Billing">
            <LoadingIndicator :state="state" all />
            <div class="g-content__columns-wrapper g-shrink">
              <div class="g-content__column-first content__column-first">

                <div class="g-section">
                  <div class="g-section-title">Plan details</div>

                  <div class="current-subscription">
                    <div class="current-subscription__name-wrapper">
                      <span class="current-subscription__text current-subscription__title">{{ currentSubscriptionTitle }} </span>
                      <span class="current-subscription__text current-subscription__name" v-if="!subscriptionUpdating">{{ userSubscriptionInfo?.billing_plan.name }}</span>
                    </div>

                    <div class="current-subscription__action-wrapper">
                      <a href="#" class="current-subscription__action g-link g-link--blue" @click="changeSubscriptionClicked">Change plan</a>
                    </div>
                  </div>
                </div>

                <div class="g-section section-billing-info">
                  <InfoAction
                    title="Payment method"
                    v-model="paymentMethodInfoActionTitle"
                    :actionTitle="userPaymentMethod?.id ? 'Update' : 'Add'"
                    @actionClick="addUpdatePaymentMethod"
                  />
                  <InfoAction
                    title="Billing email"
                    v-bind:modelValue="billingEmail"
                    :actionTitle="billingEmail ? 'Update' : 'Add'"
                    @actionClick="billingEmailEditClicked"
                  />
                  <InfoAction
                    title="Billing address"
                    v-bind:modelValue="billingAddressTitle"
                    :actionTitle="billingAddress ? 'Update' : 'Add'"
                    @actionClick="billingDetailsEditClicked"
                  />
                  <InfoAction
                    title="Billing interval"
                    v-bind:modelValue="billingPeriodInfoActionTitle"
                    actionTitle="Update"
                    :action-disabled="billingPeriodUpdating"
                    v-if="userSubscriptionInfo"
                    @actionClick="billingPeriodUpdateClicked"
                  />

                </div>

                <div class="g-section section-invoices">
                  <InfoAction
                    title="Invoices"
                    :actionTitle="'See all'"
                    class="info-action-invoices"
                    @actionClick="invoicesClicked"
                  />
                </div>

              </div>
            </div>

          </Tab>
        </Tabs>
        <div class="g-content__footer">
          <div class="g-controls__wrapper">
            <div class="g-controls__col-first">
              <Button
                class="g-controls__control"
                @click="saveProfile"
                primary
                :disabled="
                  !formPersonalValid ||
                  state.requestInProgress ||
                  !profileEdited
                "
              >
                Save
              </Button>
              <Button
                class="g-controls__control"
                @click="resetProfileForm"
                secondary
                :disabled="state.requestInProgress || !profileEdited"
                :loading="state.isUpdating"
              >
                Cancel
              </Button>
            </div>
            <div class="g-controls__col-last">
              <Button
                class="g-controls__control"
                @click="deleteProfileClicked"
                alert
                :disabled="state.requestInProgress"
                :loading="state.isUpdating"
                v-if="deletable"
              >
                Delete profile
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Dialog
      title="Delete profile?"
      ref="deleteProfileDialog"
      :buttons="[DialogButtons.Cancel, DialogButtons.DeletePrimary]"
    >
      <div class="g-text-center">Profile will be deleted with all the information.</div>
    </Dialog>

    <Dialog
      title="Delete this manager?"
      ref="deleteAccessDialog"
      :buttons="[DialogButtons.Cancel, DialogButtons.DeletePrimary]"
    />

    <ProvideAccessDialog
      title="Provide access"
      ref="provideAccessDialog"
    />

    <PaymentDialog
      ref="paymentDialog"
    />

    <BillingDetailsDialog
      ref="billingDetailsDialog"
    />

    <EmailDialog
      ref="billingEmailDialog"
    />

    <InvoicesDialog
      ref="invoicesDialog"
    />

    <ChangePlanDialog
      ref="changeSubscriptionDialog"
    />

    <ChangeBillingPeriodDialog
      ref="changeBillingPeriodDialog"
    />
  </div>
</template>

<style scoped lang="scss">

.g-controls__col-first,
.g-controls__col-last {
  flex: 1;
}

.section-title {
  font: 600 20px/24px 'Inter';
  color: #ffffff;

  margin: 68px 0 25px;
}

.subscription-details__title {
  font: 600 20px 'Inter';
  color: #ffffff;
}

.subscription-details__description {
  font: 400 16px 'Poppins';
  color: #ffffff;
  margin-top: 24px;
}

.tab-access__column-first {
  width: 540px;
}

.current-subscription {
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.current-subscription__text {
  font: 400 16px 'Poppins';
  color: #FFFFFF;
}

.current-subscription__name {
  font-weight: 500;
}

.content__column-first {
  width: 510px;
}

.section-billing-info,
.section-invoices {
  margin-top: 62px !important;
}

.info-action-invoices :deep(.title) {
  font: 600 20px 'Inter';
}

.dlg-email :deep(.wrapper) {
  min-width: 450px;
}
</style>
