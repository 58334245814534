import { notify } from '@kyvg/vue3-notification'

export function processError(errorData) {
  if (errorData === null) return

  const isDetailedError = errorData.details !== undefined

  const error = isDetailedError ? errorData.error : 'error'
  const statusCode = isDetailedError ? errorData.statusCode : ''
  const processed = isDetailedError ? errorData.processed : false
  let details = isDetailedError ? errorData.details : errorData
  if (details === '{}') details = ''

  if (processed) return

  if (statusCode === 402) {
    // emitter.$emit('showUpgradeDialog')
    notify({
      type: 'warn',
      text: 'Not available on the current plan. <a href="#" onclick="notificationLinkClick(\'Profile\')">Manage subscriptions</a>',
      duration: -1,
    })
  } else {
    notify({
      type: 'error',
      text: `API Error: ${error}. ${details}`,
      duration: -1,
    })
  }

  console.error(`API Error: ${error}`, details)
}
