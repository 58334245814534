<script setup>
import { ref, onMounted, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { useVuex } from '@vueblocks/vue-use-vuex'
import { Campaign, ViewerStyle } from '@/helpers/api/models'
import { notify } from '@kyvg/vue3-notification'
import { processError } from '@/helpers/errors'

import {
  Button,
  FileInput,
  TextInput,
  ColorPickerInput,
  SliderBranding,
  LoadingIndicator,
} from '@/components/creator'

import { deepClone } from '@/utils/common'
import { isAvailable } from '@/helpers/billingFeatures'

const state = ref({
  requestInProgress: false,
  isLoading: false,
  isUpdating: false,
  isDeleting: false,
})

const imageLogo = require('@/images/previews/logo.svg')
const imageItem = require('@/images/previews/item.svg')
const imageQr = require('@/images/previews/qrcode.svg')
const imageTutorial = require('@/images/previews/tutorial.png')

const campaign = ref(deepClone(Campaign))
const style = ref(deepClone(ViewerStyle))

const formValid = ref(false)
const inputLogo = ref(null)
const inputButtonText = ref(null)
const inputButtonColor = ref(null)
const inputButtonTextColor = ref(null)

const defaultStyle = {
  logo: imageLogo,
  button: {
    text: 'View in AR',
    background:
      'linear-gradient(270deg, rgb(0, 254, 254) 0%, rgb(40, 203, 255) 100%)',
    color: '#000000',
  },
}

const brandingIsAvailable = computed(() => {
  return isAvailable('AR_VIEWER_CUSTOMIZATION_ENABLED')
})

const formIsValidity = computed(() => {
  return !!(
    formValid.value &&
    inputLogo.value?.valid &&
    inputButtonColor.value?.valid &&
    inputButtonTextColor.value?.valid
  )
})

const modified = computed(() => {
  return JSON.stringify(campaign.value.branding) !== JSON.stringify(style.value)
})

const fullLogoUrl = computed(() => {
  if (style.value.logo) {
    return `${process.env.VUE_APP_CONTENT_BASE_URL}/${style.value.logo}`
  }

  return imageLogo
})
const buttonBackground = computed(
  () =>
    (inputButtonColor.value?.valid && style.value.button.background) ||
    defaultStyle.button.background
)
const buttonColor = computed(
  () =>
    (inputButtonTextColor.value?.valid && style.value.button.color) ||
    defaultStyle.button.color
)
const buttonText = computed(
  () => style.value.button.text || defaultStyle.button.text
)

const store = useStore()
const { useActions } = useVuex(null, store)
const { apiCampaignsGet, apiProfilesGet, apiCampaignsUpdate } = useActions({
  apiCampaignsGet: 'apiCampaignsGet',
  apiProfilesGet: 'apiProfilesGet',
  apiCampaignsUpdate: 'apiCampaignsUpdate',
})

const router = useRouter()
const route = useRoute()

function showArListPage () {
  router.push({
    name: 'ArList',
  })
}
function close () {
  showArListPage()
}

async function update () {
  try {
    state.value.requestInProgress = true
    state.value.isUpdating = true

    const newCampaign = deepClone(campaign.value)
    newCampaign.branding = deepClone(style.value)

    await apiCampaignsUpdate({ data: newCampaign })

    notify({
      type: 'success',
      text: 'Custom style has been updated.',
    })

    showArListPage()
  } catch (e) {
    processError(e)
  } finally {
    state.value.isUpdating = false
    state.value.requestInProgress = false
  }
}

async function loadData () {
  try {
    state.value.requestInProgress = true
    state.value.isLoading = true

    campaign.value = (
      await apiCampaignsGet({ id: route.params.campaign_id })).content

    style.value = Object.assign(
      deepClone(ViewerStyle),
      deepClone(campaign.value.branding)
    )

    if (!campaign.value.profile?.id) {
      throw Error('no profile id')
    }

    campaign.value.profile.value = (await apiProfilesGet({ id: campaign.value.profile.id })).content
  } catch (e) {
    if (e.value === 'NotFound') {
      router.replace({ name: 'NotFound' })
      return
    }

    processError(e)
  } finally {
    state.value.isLoading = false
    state.value.requestInProgress = false
  }
}

onMounted(async () => {
  await loadData()
})
</script>

<template>
  <div class="viewer-branding-wrapper">
    <div class="g-title-wrapper">
      <div class="g-title-inner">
        <h1 class="g-title">Custom viewer</h1>
      </div>
    </div>

    <div class="g-content g-shrink">
      <div class="g-content__inner g-shrink">
        <LoadingIndicator :state="state" all />
        <div class="g-content__columns-wrapper g-shrink">
          <div class="g-content__column-first">
            <v-form v-model="formValid">
              <div v-if="!brandingIsAvailable" class="need-upgrade">
                <p class="need-upgrade__title">Upgrade to customize the AR viewer</p>
                <Button class="need-upgrade__button" primary small @click="router.push({ name: 'Profile', query: { tab: 'Billing'}})">
                  Upgrade
                </Button>
              </div>

              <FileInput
                v-model="style.logo"
                :extensions="['.jpg', '.jpeg', '.png', '.svg']"
                placeholder="Image for loading screen"
                description="JPG, PNG, SVG | Max 100kb | Min 240x240px"
                ref="inputLogo"
                :readonly="!brandingIsAvailable"
                :validator="{
                  size: { max: 100000 },
                  picture: {
                    height: { min: 240 },
                    width: { min: 240 },
                  },
                }"
              />

              <div class="g-section-title" :class="{ disabled: !brandingIsAvailable }">button</div>

              <TextInput
                v-model="style.button.text"
                type="text"
                label="Text"
                ref="inputButtonText"
                :hint="`${style.button.text?.length || 0}/28`"
                maxlength="28"
                :disabled="!brandingIsAvailable"
              />

              <div class="g-section color-picker__wrapper">
                <ColorPickerInput
                  ref="inputButtonColor"
                  label="Button BG color Hex"
                  v-model="style.button.background"
                  class="picker-bg"
                  :disabled="!brandingIsAvailable"
                />

                <ColorPickerInput
                  ref="inputButtonTextColor"
                  label="Button text color Hex"
                  v-model="style.button.color"
                  class="picker-text"
                  :disabled="!brandingIsAvailable"
                />
              </div>
            </v-form>
          </div>

          <div class="g-content__column-second">
            <div class="preview-title">Preview</div>
            <div class="preview-wrapper">
              <SliderBranding>
                <div
                  class="slide slide-loading"
                  :data="{ title: 'Loading screen' }"
                >
                  <div class="preview-loading">
                    <img class="preview-loading__image" :src="fullLogoUrl" />
                    <div class="preview-loading__text">17%</div>
                  </div>
                </div>
                <div class="slide slide-item" :data="{ title: 'Web preview' }">
                  <img class="preview-web__image" :src="imageItem" />
                  <button class="preview-web__btn">{{ buttonText }}</button>
                </div>
                <div class="slide slide-qr" :data="{ title: 'QR code popup' }">
                  <img class="preview-qr__image" :src="imageQr" />
                </div>
                <div
                  class="slide slide-tutorial"
                  :data="{ title: 'Tutorial popup' }"
                >
                  <div class="preview-tutorial">
                    <img class="preview-tutorial__image" :src="imageTutorial" />

                    <button class="preview-tutorial__btn preview-web__btn">
                      {{ buttonText }}
                    </button>
                  </div>
                </div>
              </SliderBranding>
            </div>
          </div>
        </div>

        <div class="g-content__footer">
          <div class="g-controls__wrapper">
            <div class="g-controls__col-first">
              <Button
                class="g-controls__control"
                @click="close"
                :disabled="state.requestInProgress"
                >Close</Button
              >
              <Button
                class="g-controls__control"
                @click="update"
                primary
                :disabled="
                  !modified || state.requestInProgress || !formIsValidity || !brandingIsAvailable
                "
                :loading="state.isUpdating"
                >Update</Button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.color-picker__wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.need-upgrade {
  margin-bottom: 22px;
}

.need-upgrade__title {
  font: 400 14px 'Inter';
}

.need-upgrade__button {
  margin-top: 16px;
}

.picker-bg :deep(.picker) {
  left: 1px;
}

.picker-text :deep(.picker) {
  right: 0;
}

.preview-title {
  font: 600 16px 'Inter';
  color: #909eaf;
}

.preview-wrapper {
  height: 508px;
  margin-top: 12px;
}

.preview-loading {
  margin: auto;
  text-align: center;
}

.preview-loading__image {
  width: 120px;
  height: 120px;
}

.preview-loading__text {
  font: 400 12px 'Inter';
  color: #131e28;
}

.slide {
  display: flex;
  flex-direction: column;
}

.preview-web__image {
  width: 210px;
  flex: 1;
  margin-left: auto;
  margin-right: auto;
}

.preview-web__btn {
  width: fit-content;
  height: 31px;
  padding: 0 32px;
  margin: 0 auto 43px;
  border-radius: 28px;
  font: normal 15px 'Inter';
  letter-spacing: 0.002em;
  background: v-bind('buttonBackground');
  color: v-bind('buttonColor');
}

.preview-qr__image {
  width: 290px;
  margin: auto;
}

.preview-tutorial {
  margin: auto;
  position: relative;
}

.preview-tutorial__image {
  width: 302px;
}

.preview-tutorial__btn {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 329px;
  padding-left: 16px;
  padding-right: 16px;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 20ch;
}

.slider {
  height: 100%;
  display: flex;
  flex-direction: column;

  :deep(.view) {
    background-color: white;
    position: relative;
    width: 631px;
  }

  :deep(.footer) {
    height: auto;
    margin-top: 11px;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    align-self: stretch;
  }

  :deep(.footer > .title) {
    color: white;
    position: static;
  }

  :deep(.footer > .switer) {
    margin-left: auto;
    margin-right: 14px;
    position: static;
  }

  :deep(.button button.transparent:hover) {
    background: none;
  }
}
</style>
