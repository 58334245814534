
<script setup>
import { ref, defineProps, defineEmits, onMounted, watch } from 'vue'

const props = defineProps({
  minValue: Number,
  maxValue: Number,
  modelValue: Number,
  disabled: {
    type: Boolean,
    default: false,
  }
})

const currentValue = ref(0)

onMounted(() => {
  currentValue.value = props.modelValue

  watch(currentValue, (value) => {
    emit('update:modelValue', value)
  })
  watch(props, (value) => {
    currentValue.value = value.modelValue
  })
})

const emit = defineEmits(['update:modelValue'])
</script>

<template>
  <div class="slider-wrapper">
    <div class="slider-limit slider-min-value" :class="{ disabled: disabled }">{{ props.minValue }}</div>

    <v-slider
      step="1"
      :min="props.minValue"
      :max="props.maxValue"
      class="slider-element"
      :class="{ disabled: disabled }"
      hide-details
      color="#00D7FF"
      track-color="#26425B"
      thumb-label="always"
      v-model="currentValue"
      :disabled="props.disabled"
    ></v-slider>

    <div class="slider-limit slider-max-value" :class="{ disabled: disabled }">{{ props.maxValue }}</div>
  </div>

</template>

<style lang="scss" scoped>

.slider-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.slider-limit {
  background: #091520;
  border-radius: 4px;
  flex: 0;
  min-width: 36px;
  height: 24px;
  text-align: center;
  padding-top: 2px;
  min-height: 25px;

  font: normal 14px'Poppins';
  color: #FFFFFF;

  &.disabled {
    color: #334656;
  }
}

.slider-element {
  flex: 1;
}

.slider-element.v-input--horizontal {
  margin-left: 20px;
  margin-right: 20px;
}

.slider-element :deep(.v-slider-thumb__label::before) {
  display: none;
}

.slider-element :deep(.v-slider-thumb__label) {
  top: -2px;
  left: -18px;

  background: #091520;
  border: 1px solid #00D7FF;
  border-radius: 4px;

  font: normal 14px 'Poppins';
  color: #FFFFFF;
}

.slider-element.disabled :deep(.v-slider-thumb__label) {
  color: #334656;
}

.slider-element :deep(.v-slider-thumb--focused .v-slider-thumb__surface::before) {
  opacity: 1;
}

.slider-element :deep(
  .v-slider-thumb--focused .v-slider-thumb__surface::before,
  .v-slider-thumb:hover .v-slider-thumb__surface::before) {
  transform: scale(1);
}

.slider-element :deep(.v-input__control) {
  min-height: 26px !important;
}

.slider-element :deep(.v-slider-thumb__label) {
  transform: none !important;
}
</style>
