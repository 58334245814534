<script setup>
import { ref, computed } from 'vue'
import AccessSettingsDialog from '@/components/creator/dialogs/AccessSettingsDialog'
import { ToggleSwitch } from '@/components/creator'
import { isAvailable } from '@/helpers/billingFeatures'

const accessSettingsToggle = ref(null)

const props = defineProps({
  space: {
    type: Object,
    required: true,
  },
})

const emit = defineEmits(['update:space'])

const tokenAclExists = computed(() => props.space.acl?.length > 0)

const aclEnabled = computed({
  get: () => props.space.acl_enabled,
  set: (value) => {
    emit('update:space', { ...props.space, acl_enabled: value })
  }
})

const showAccessSettings = async () => {
  if (aclEnabled.value) {
    await accessSettingsToggle.value.show()
  }
}

</script>

<template>
  <div class="g-section" v-if="isAvailable('WORLDS_ACL')">
    <div class="g-section-title g-section-title-limitations">
      <span class="title">Private Space</span>
      <ToggleSwitch v-model="aclEnabled" />
    </div>
    <div class="toggle-switch-container">
      <span class="toggle-label" :class="{ 'disabled': !aclEnabled }">
        Private access for specific NFT holders
      </span>
      <div class="toggle-switch-wrapper">
        <span class="toggle-label" :class="{ 'disabled': !aclEnabled }">
          <button :class="{ 'no-cursor': !aclEnabled }" @click="showAccessSettings">
            <template v-if="tokenAclExists">Edit</template>
            <template v-else>Enable</template>
          </button>
        </span>
      </div>
    </div>
    <AccessSettingsDialog
      ref="accessSettingsToggle"
      :space="props.space"
      @update:space="emit('update:space', $event)"
    />
  </div>
</template>

<style lang="scss" scoped>

  .g-section {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

 .g-section-title-limitations {
   margin-bottom: 14px;
   display: flex;
   flex-direction: row;
   align-items: center;
   flex: 1;
   width: 100%;

   .title {
    flex: 1;
   }
 }

 .toggle-switch-container {
   display: flex;
   align-items: center;
   flex-direction: row;
   gap: 10px;
 }

 .toggle-switch-wrapper {
   display: flex;
   flex-direction: row;
   align-items: flex-start; /* Align items to the left */
   padding-left: 20px; /* Move the toggle switch to the right */
 }

 .toggle-label {
   margin-top: 6px;
   font: 400 14px 'Inter';
   flex: 1;
 }
 .disabled {
   color: #909EAF;
 }

 .no-cursor {
  cursor: default;
}
</style>
