<script setup>
import Chart from 'chart.js/auto'
import { ref, onMounted, watch } from 'vue'
import dateFormat from 'dateformat'

const props = defineProps({
  data: {
    type: Array,
    required: true,
  },
  chartDateFormat: {
    type: String,
    default: 'mmm d',
  },
})

const canvas = ref(null)
let chart

watch(() => props.data, () => {
  chart.data.labels = props.data.map(row => row.date)
  chart.data.datasets[0].data = props.data.map(row => row.value)

  chart.update()
})

onMounted(() => {
  const pluginBg = {
    id: 'customCanvasBackgroundColor',
    beforeDraw: (chart, args, options) => {
      const { ctx, chartArea: { top, left, width, height } } = chart
      ctx.save()
      ctx.globalCompositeOperation = 'destination-over'
      ctx.fillStyle = options.color || '#091520'
      ctx.fillRect(left, top, width, height)
      ctx.restore()
    },
  }

  const chartAreaBorder = {
    id: 'chartAreaBorder',
    beforeDraw(chart, args, options) {
      const { ctx, chartArea: { left, top, width, height } } = chart
      ctx.save()
      ctx.strokeStyle = options.borderColor
      ctx.lineWidth = options.borderWidth
      ctx.setLineDash(options.borderDash || [])
      ctx.lineDashOffset = options.borderDashOffset
      ctx.strokeRect(left, top, width, height)
      ctx.restore()
    },
  }

  // eslint-disable-next-line no-new
  chart = new Chart(
    canvas.value,
    {
      type: 'line',
      data: {
        labels: props.data.map(row => row.date),
        datasets: [
          {
            label: '',
            data: props.data.map(row => row.value),
            fill: 'origin',
            borderColor: '#00D7FF',
            borderWidth: 2,
            pointRadius: 5,
            pointBorderColor: '#00D7FF',
            pointBackgroundColor: 'rgba(0, 0, 0, 1)',
            pointBorderWidth: '2',
            pointHoverBackgroundColor: 'rgba(0, 0, 0, 1)',
            pointHoverBorderWidth: '2',
            pointHoverRadius: 6,
            yAxisID: 'yAxis',
            xAxisID: 'xAxis',
            backgroundColor: 'rgba(0, 215, 255, 0.2)',
          },
        ],
      },
      options: {
        scales: {
          yAxis: {
            position: 'right',
            beginAtZero: true,
            grid: {
              color: '#667F92',
            },
            grace: '50%',
            ticks: {
              count: 4,
              precision: 0,
              callback: function(val) {
                return Math.floor(val)
              },
            },
          },
          xAxis: {
            ticks: {
              callback: function(value, index, ticks) {
                const dt = props.data[index].date
                return dateFormat(dt, props.chartDateFormat)
              },
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          chartAreaBorder: {
            borderColor: '#D2D9E7',
            borderWidth: 1,
          },
          tooltip: {
            displayColors: false,
            callbacks: {
              label: function(context) {
                return 'Views ' + context.parsed.y
              },
              title: function(context) {
                const dt = props.data[context[0].dataIndex].date

                return dateFormat(dt, props.chartDateFormat)
                // return 'Views '// + context.parsed.y
              },
            },
          },
        },
        maintainAspectRatio: false,
      },
      plugins: [pluginBg, chartAreaBorder],
    }
  )
})

</script>

<template>
  <div class="chart__wrapper">
    <canvas class="canvas" ref="canvas"></canvas>
  </div>
</template>

<style lang="scss" scoped>
.canvas {
}
</style>
