<script setup>
import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { useVuex } from '@vueblocks/vue-use-vuex'
import {
  TextInput,
  FileInput,
  FileDrop,
  Button,
  Select,
  LoadingIndicator,
} from '@/components/creator'
import { ContentItem } from '@/helpers/api/models'
import { deepClone, getFileExtension } from '@/utils/common'
import creatorItemTypes from '@/helpers/creatorItemTypes'
import isEqual from 'lodash-es/isEqual'
import { processError } from '@/helpers/errors'

const state = ref({
  requestInProgress: false,
  isLoading: false,
  isUpdating: false,
  isDeleting: false,
})

const contentItem = ref(deepClone(ContentItem))
const type = ref(null)
const file = ref(null)
const fileInput = ref(null)
const isValid = ref(false)

const nameRule = (v) => !!v || 'Required'

const formValid = computed(() => isValid.value && fileInput.value?.valid)

const typeItems = ref([])
const exclude = ['all', 'ar']
for (const key in creatorItemTypes) {
  if (exclude.includes(key)) continue

  const item = creatorItemTypes[key]
  typeItems.value.push({ value: item, text: item.title })
}

const store = useStore()
const { useActions } = useVuex(null, store)
const { apiContentItemsCreate, apiConvertFbx, apiSpaceAvatarsCreate, apiPlatformAvatarsCreate } = useActions([
  'apiContentItemsCreate',
  'apiConvertFbx',
  'apiSpaceAvatarsCreate',
  'apiPlatformAvatarsCreate',
])

const router = useRouter()
const route = useRoute()

const typeDisabled = computed(() => {
  return parseInt(route.query.typeDisabled) === 1
})

async function createContentItem () {
  try {
    state.value.requestInProgress = true
    state.value.isUpdating = true

    const filename = file.value
    if (isEqual(type.value, creatorItemTypes.ar)) {
      // AR convert always to get usdz
      const convertResult = (await apiConvertFbx({ filename: filename }))
        .content
      contentItem.value.media.glbmodel = convertResult.glb
      contentItem.value.media.usdzmodel = convertResult.usdz
    } else if (
      isEqual(type.value, creatorItemTypes.glb) ||
      isEqual(type.value, creatorItemTypes.avatar)
    ) {
      // GLB and AVATAR convert only if fbx or fbx.zip
      const extension = getFileExtension(filename).toLowerCase()
      if (extension === '.fbx' || extension === '.zip') {
        const convertResult = (await apiConvertFbx({ filename: filename }))
          .content
        contentItem.value.media.glbmodel = convertResult.glb
      } else {
        contentItem.value.media.glbmodel = filename
      }
    } else if (isEqual(type.value, creatorItemTypes.image)) {
      contentItem.value.media.image = filename
    } else if (isEqual(type.value, creatorItemTypes.video)) {
      contentItem.value.media.video = filename
    } else if (isEqual(type.value, creatorItemTypes.sound)) {
      contentItem.value.media.sound = filename
    } else {
      throw new Error('Unknown type')
    }

    contentItem.value.type = type.value.contentType
    contentItem.value = (
      await apiContentItemsCreate({
        data: contentItem.value,
      })
    ).content

    if (route.query.space) {
      await apiSpaceAvatarsCreate({
        spaceRef: { id: route.query.space },
        contentItemRef: contentItem.value,
      })
    }
    if (route.query.platform) {
      await apiPlatformAvatarsCreate({
        contentItemRef: contentItem.value,
      })
    }

    router.push({
      name: 'StorageItem',
      params: { id: contentItem.value.id },
      query: route.query.from ? { from: route.query.from } : {},
    })
  } catch (error) {
    processError(error)
  } finally {
    state.value.requestInProgress = false
    state.value.isUpdating = false
  }
}

onMounted(() => {
  if (route.query.typeInit) {
    type.value = creatorItemTypes[route.query.typeInit]
  }
})
</script>

<template>
  <div class="storage-new-wrapper">
    <div class="g-title-wrapper">
      <h1 class="g-title">New content</h1>
    </div>

    <div class="g-content">
      <div class="g-form-upload-wrapper">
        <v-form v-model="isValid">
          <LoadingIndicator :state="state" all />

          <TextInput
            ref="textInputName"
            v-model="contentItem.name"
            name="name"
            type="text"
            label="Content name"
            autofocus
            :rules="[nameRule]"
          />

          <Select
            place-holder="Choose type"
            :items="typeItems"
            v-model="type"
            @update:modelValue="file = null"
            :disabled="fileInput?.uploading || typeDisabled"
          />

          <FileDrop
            v-show="type !== null"
            ref="textFileDrop"
            :fileInput="fileInput"
            :fileSpecs="type?.textSpecs || ''"
          />

          <FileInput
            v-show="type !== null"
            ref="fileInput"
            v-model="file"
            :extensions="type?.storageUploadExt"
            placeholder="Content file"
            required
            :validator="type?.fileValidator"
          />

          <div class="btn-create-wrapper">
            <Button
              primary
              class="btn-create"
              @click="createContentItem"
              :disabled="!formValid || state.requestInProgress"
              :loading="state.isUpdating"
            >
              Create
            </Button>
          </div>
        </v-form>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.file-drop-wrapper {
  margin-top: 38px;
}

.file-input-wrapper {
  margin-top: 28px;
}

.btn-create-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 72px;
}
</style>
