<script setup>
import { ref, onMounted, computed } from 'vue'
import Dialog from '@/components/common/dialogs/Dialog'
import * as DialogButtons from '@/components/common/dialogs/DialogButtons'
import { BillingDetailsForm } from '@/components/creator/payments/stripe'
import { processError } from '@/helpers/errors'

const billingDetailsForm = ref(null)

const dialog = ref(null)
const isValid = ref(false)
const name = ref(null)
const address = ref(null)

const props = defineProps({
  title: String,
})

const dialogButtons = ref([
  [
    DialogButtons.Cancel,
    {
      ...DialogButtons.Save,
      onClick: () => { save() },
      variant: computed(() => {
        return isValid.value ? 'primary' : 'primary disabled'
      }),
    },
  ],
])

const show = async (initName, initAddress) => {
  try {
    name.value = initName
    address.value = initAddress
    const result = await dialog.value.show()
    if (result === 'save') {
      return {
        result: true,
        name: name.value,
        address: address.value,
      }
    }
  } catch (error) {
    processError(error)
    dialog.value.close()
    return {
      result: false,
      error: error,
    }
  }
}

async function save () {
  dialog.value.close('save')
}

function billingDetailsFormChanged (data) {
  isValid.value = data.valid
  name.value = data.name
  address.value = data.address
}

onMounted(() => {

})

defineExpose({ show })
</script>

<template>
  <Dialog
    :title="props.title || 'Billing information'"
    ref="dialog"
    :buttons="dialogButtons"
    class="billing-details-dialog__wrapper"
  >
    <BillingDetailsForm
      ref="billingDetailsForm"
      @change="billingDetailsFormChanged"
      :initAddress="address"
      :initName="name"
    />
  </Dialog>
</template>

<style lang="scss" scoped>

.billing-details-dialog__wrapper > :deep(.wrapper) {
  min-width: 339px;
}

</style>
