import { API } from '@/helpers/api'
import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import VueJwtDecode from 'vue-jwt-decode'
import router from '@/router'

export default createStore({
  state: {
    table: {},
    view: {},
    authToken: null,
    auth: null,
    clientState: {
      selectedTabName: 'Settings',
    },
    nftStudio: {
      networkType: '',
      networkAddress: '',
      tokenStatus: '',
      isTokenOwner: false,
      isNftStudioLoading: null,
    },
  },
  actions: {
    apiRequest: async function (
      context,
      {
        endpoint,
        method,
        data,
        progressCallback = null,
        isLoginRequest = false,
      }
    ) {
      const { response, options } = await API.request(
        endpoint,
        method,
        data,
        this.state.authToken,
        progressCallback
      )

      if (!response.success) {
        switch (options.statusCode) {
          case 401:
            context.commit('setAuthState', false)
            // eslint-disable-next-line no-throw-literal
            throw {
              error: 'Authentication required',
              statusCode: options.statusCode,
              details: '',
              processed: true,
            }
          case 403:
            router.replace({ name: 'Forbidden' })
            // eslint-disable-next-line no-throw-literal
            throw {
              error: 'Forbidden',
              statusCode: options.statusCode,
              details: '',
              processed: true,
            }
          default: {
            const errorDetails = (typeof response.content === 'string') ? response.content : JSON.stringify(response.content)
            // eslint-disable-next-line no-throw-literal
            throw {
              error: response.error,
              statusCode: options.statusCode,
              details: errorDetails,
            }
          }
        }
      }

      if (
        options.headers &&
        'x-access-token' in options.headers &&
        (
          isLoginRequest ||
          this.state.authToken
        )
      ) {
        const token = options.headers['x-access-token']
        this.state.authToken = token
        context.dispatch('apiLoginParseToken', { token })
      }

      return response
    },

    s3Request: function (
      context,
      { url, file, metadata, progressCallback = null }
    ) {
      return new Promise((resolve) => {
        API.s3request(url, file, metadata, progressCallback).then(
          (result) => {
            resolve(result)
          }
        )
      })
    },

    apiLogin (context, { email, password }) {
      return context.dispatch('apiRequest', {
        endpoint: '/auth/login',
        method: 'POST',
        data: { email, password },
        isLoginRequest: true,
      })
    },

    apiLogout: async function (context) {
      context.commit('setAuthState', false)
    },

    apiResetPassword (context, { email }) {
      return context.dispatch('apiRequest', {
        endpoint: '/auth/reset_password',
        method: 'POST',
        data: { email },
      })
    },

    apiResetVerify (context, { link }) {
      return context.dispatch('apiRequest', {
        endpoint: `/auth/reset_password/${link}`,
        method: 'GET',
      })
    },

    apiSetPassword (context, { link, email, password }) {
      return context.dispatch('apiRequest', {
        endpoint: '/auth/reset_password',
        method: 'PUT',
        data: { link, email, password },
      })
    },

    async apiGetSaToken (context) {
      return await context.dispatch('apiRequest', {
        endpoint: '/auth/sa',
        method: 'GET',
      })
    },

    async apiSwitchProfile (context, { profile }) {
      return await context.dispatch('apiRequest', {
        endpoint: '/auth/switch_profile',
        method: 'POST',
        data: { profile },
      })
    },

    apiUsersList: async function (context) {
      return new Promise((resolve) => {
        context
          .dispatch('apiRequest', {
            endpoint: '/users/',
            method: 'GET',
            data: {},
          })
          .then((apiresult) => {
            resolve(apiresult)
          })
      })
    },

    apiUsersGet: async function (context, { id = undefined, email = undefined }) {
      if (!id && !email) throw Error('id or email required')

      const endpoint = id ? `/users/?id=${id}` : `/users/?email=${encodeURIComponent(email)}`

      return await context.dispatch('apiRequest', {
        endpoint: endpoint,
        method: 'GET',
        data: {},
      })
    },

    apiUsersCreate: async function (context, { data }) {
      return new Promise((resolve) => {
        context
          .dispatch('apiRequest', {
            endpoint: '/users/',
            method: 'POST',
            data: data,
          })
          .then((apiresult) => {
            resolve(apiresult)
          })
      })
    },

    apiUsersUpdate: async function (context, { data }) {
      return await context.dispatch('apiRequest', {
        endpoint: '/users/',
        method: 'PUT',
        data: data,
      })
    },

    apiUsersDelete: async function (context, { id, email }) {
      return await context.dispatch('apiRequest', {
        endpoint: `/users/${id}`,
        method: 'DELETE',
        data: { id, email },
      })
    },

    apiPing: async function (context) {
      return await context.dispatch('apiRequest', {
        endpoint: '/utils/ping',
        method: 'GET',
        data: {},
      })
    },

    apiContentItemsList: async function (context, { page, pageSize, search, contentType }) {
      let paginationArgs = ''
      if (pageSize && pageSize > 1) {
        paginationArgs += `?page=${page}&page_size=${pageSize}`
      }
      let searchArgs = ''
      if (search && search.trim() !== '') {
        const prefix = paginationArgs ? '&' : '?'
        searchArgs = `${prefix}search=${encodeURIComponent(search.trim())}`
      }
      let contentTypeArgs = ''
      if (contentType && contentType.trim() !== '') {
        const prefix = paginationArgs || searchArgs ? '&' : '?'
        contentTypeArgs = `${prefix}content_type=${encodeURIComponent(contentType.trim())}`
      }
      return await context.dispatch('apiRequest', {
        endpoint: '/content_items/' + paginationArgs + searchArgs + contentTypeArgs,
        method: 'GET',
        data: {},
      })
    },

    apiContentItemsListByIds: async function (context, { ids } = {}) {
      return await context.dispatch('apiRequest', {
        endpoint: '/content_items/list/by_ids',
        method: 'POST',
        data: {
          items: ids,
        },
      })
    },

    apiContentItemsGet: async function (context, { data }) {
      return await context.dispatch('apiRequest', {
        endpoint: `/content_items/${data}`,
        method: 'GET',
      })
    },

    apiContentItemsCreate: async function (context, { data }) {
      return await context.dispatch('apiRequest', {
        endpoint: '/content_items/',
        method: 'POST',
        data: {
          content_item: data,
        },
      })
    },

    apiContentItemsUpdate: async function (context, { data }) {
      return await context.dispatch('apiRequest', {
        endpoint: '/content_items/',
        method: 'PUT',
        data: {
          id: data.id,
          content_item: data,
        },
      })
    },

    apiContentItemsDelete: async function (context, { id }) {
      return await context.dispatch('apiRequest', {
        endpoint: `/content_items/${id}`,
        method: 'DELETE',
      })
    },

    apiContentItemsFilesGet: async function (context, { filename }) {
      return new Promise((resolve) => {
        context
          .dispatch('apiRequest', {
            endpoint: `/content_items/files/${filename}`,
            method: 'GET',
          })
          .then((apiresult) => {
            resolve(apiresult)
          })
      })
    },

    // eslint-disable-next-line camelcase
    apiContentItemsFilesPut: async function (context, { original_filename }) {
      return await context.dispatch('apiRequest', {
        endpoint: '/content_items/files',
        method: 'PUT',
        data: { original_filename: original_filename }, // eslint-disable-line camelcase
      })
    },

    apiContentItemsFilesPutToS3: async function (
      context,
      { url, file, metadata = null, progressCallback = null }
    ) {
      return await context.dispatch('s3Request', {
        url: url,
        file: file,
        metadata: metadata,
        progressCallback: progressCallback,
      })
    },

    apiProfilesList: async function (context) {
      return await context.dispatch('apiRequest', {
        endpoint: '/profiles/',
        method: 'GET',
        data: {},
      })
    },

    apiProfilesGet: async function (context, { id }) {
      return await context.dispatch('apiRequest', {
        endpoint: `/profiles/${id}`,
        method: 'GET',
      })
    },

    apiProfilesCreate: async function (context, { data }) {
      return await context.dispatch('apiRequest', {
        endpoint: '/profiles/',
        method: 'POST',
        data: {
          profile: data,
        },
      })
    },

    apiProfilesUpdate: async function (context, { data }) {
      return await context.dispatch('apiRequest', {
        endpoint: '/profiles/',
        method: 'PUT',
        data: {
          id: data.id,
          profile: data,
        },
      })
    },

    apiProfilesDelete: async function (context, { id }) {
      return await context.dispatch('apiRequest', {
        endpoint: `/profiles/${id}`,
        method: 'DELETE',
      })
    },

    // eslint-disable-next-line camelcase
    apiCampaignsList: async function (context) {
      return await context.dispatch('apiRequest', {
        // eslint-disable-next-line camelcase
        endpoint: '/profiles/campaigns',
        method: 'GET',
      })
    },

    apiCampaignsGet: async function (context, { id }) {
      return await context.dispatch('apiRequest', {
        endpoint: `/campaigns/${id}`,
        method: 'GET',
      })
    },

    apiCampaignsCreate: async function (context, { data }) {
      return await context.dispatch('apiRequest', {
        endpoint: '/campaigns/',
        method: 'POST',
        data: {
          campaign: data,
        },
      })
    },

    apiCampaignsUpdate: async function (context, { data }) {
      return await context.dispatch('apiRequest', {
        endpoint: '/campaigns/',
        method: 'PUT',
        data: {
          id: data.id,
          campaign: data,
        },
      })
    },

    apiCampaignsDelete: async function (context, { id }) {
      return await context.dispatch('apiRequest', {
        endpoint: `/campaigns/${id}`,
        method: 'DELETE',
      })
    },

    // eslint-disable-next-line camelcase
    apiProjectsListByCampaign: async function (context, { campaign_id }) {
      return await context.dispatch('apiRequest', {
        // eslint-disable-next-line camelcase
        endpoint: `/campaigns/${campaign_id}/projects`,
        method: 'GET',
      })
    },

    // eslint-disable-next-line camelcase
    apiProjectsListByProfile: async function (context) {
      return await context.dispatch('apiRequest', {
        // eslint-disable-next-line camelcase
        endpoint: '/profiles/projects',
        method: 'GET',
      })
    },

    apiProjectsGet: async function (context, { id }) {
      return new Promise((resolve) => {
        context
          .dispatch('apiRequest', {
            endpoint: `/projects/${id}`,
            method: 'GET',
          })
          .then((apiresult) => {
            resolve(apiresult)
          })
      })
    },

    apiProjectsNewId: async function (context) {
      return await context.dispatch('apiRequest', {
        endpoint: '/projects/new_id',
        method: 'GET',
      })
    },

    apiProjectsCreate: async function (context, { data }) {
      return await context.dispatch('apiRequest', {
        endpoint: '/projects/',
        method: 'POST',
        data: {
          project: data,
        },
      })
    },

    apiProjectsUpdate: async function (context, { data }) {
      return await context.dispatch('apiRequest', {
        endpoint: '/projects/',
        method: 'PUT',
        data: {
          id: data.id,
          project: data,
        },
      })
    },

    apiProjectsDelete: async function (context, { id }) {
      return await context.dispatch('apiRequest', {
        endpoint: `/projects/${id}`,
        method: 'DELETE',
      })
    },

    apiArList: async function (context, { page, pageSize, search }) {
      let paginationArgs = ''
      if (pageSize && pageSize > 1) {
        paginationArgs += `?page=${page}&page_size=${pageSize}`
      }
      let searchArgs = ''
      if (search && search.trim() !== '') {
        const prefix = paginationArgs ? '&' : '?'
        searchArgs = `${prefix}search=${encodeURIComponent(search.trim())}`
      }
      return await context.dispatch('apiRequest', {
        endpoint: '/ar/' + paginationArgs + searchArgs,
        method: 'GET',
      })
    },

    apiSpaceTemplatesList: async function (context, { page, pageSize }) {
      let paginationArgs = ''
      if (pageSize && pageSize > 1) {
        paginationArgs += `?page=${page}&page_size=${pageSize}`
      }
      return await context.dispatch('apiRequest', {
        endpoint: '/spacetemplates/' + paginationArgs,
        method: 'GET',
      })
    },

    apiSpaceTemplatesGet: async function (context, {
      id = false,
    }) {
      return await context.dispatch('apiRequest', {
        endpoint: `/spacetemplates/${id}`,
        method: 'GET',
      })
    },

    apiSpaceTemplatesCreate: async function (context, { data }) {
      return await context.dispatch('apiRequest', {
        endpoint: '/spacetemplates/',
        method: 'POST',
        data: {
          space_template: data,
        },
      })
    },

    apiSpaceTemplatesUpdate: async function (context, { data }) {
      return await context.dispatch('apiRequest', {
        endpoint: '/spacetemplates/',
        method: 'PUT',
        data: {
          id: data.id,
          space_template: data,
        },
      })
    },

    apiSpaceTemplatesDelete: async function (context, { id }) {
      return await context.dispatch('apiRequest', {
        endpoint: `/spacetemplates/${id}`,
        method: 'DELETE',
      })
    },

    apiSpacesList: async function (context, { page, pageSize, search }) {
      let paginationArgs = ''
      if (pageSize && pageSize > 1) {
        paginationArgs += `?page=${page}&page_size=${pageSize}`
      }
      let searchArgs = ''
      if (search && search.trim() !== '') {
        const prefix = paginationArgs ? '&' : '?'
        searchArgs = `${prefix}search=${encodeURIComponent(search.trim())}`
      }
      return await context.dispatch('apiRequest', {
        endpoint: '/spaces/' + paginationArgs + searchArgs,
        method: 'GET',
      })
    },

    apiSpacesGet: async function (context, { id }) {
      return await context.dispatch('apiRequest', {
        endpoint: `/spaces/${id}`,
        method: 'GET',
      })
    },

    apiSpacesCreate: async function (context, { space = null, fromExported = null }) {
      if (!space && !fromExported) throw new Error('data or fromExported must be provided')

      const data = space ? { space: space } : { from_exported: fromExported }

      return await context.dispatch('apiRequest', {
        endpoint: '/spaces/',
        method: 'POST',
        data: data,
      })
    },

    apiSpacesUpdate: async function (context, { data }) {
      return await context.dispatch('apiRequest', {
        endpoint: '/spaces/',
        method: 'PUT',
        data: {
          id: data.id,
          space: data,
        },
      })
    },

    apiSpacesDelete: async function (context, { id }) {
      return await context.dispatch('apiRequest', {
        endpoint: `/spaces/${id}`,
        method: 'DELETE',
      })
    },

    apiSpacesExport: async function (context, { id, includeContent }) {
      return await context.dispatch('apiRequest', {
        endpoint: '/spaces/export/',
        method: 'POST',
        data: {
          id: id,
          include_content: includeContent,
        },
      })
    },

    apiSlotsList: async function (context, { spaceId }) {
      return await context.dispatch('apiRequest', {
        // eslint-disable-next-line camelcase
        endpoint: `/spaces/${spaceId}/slots`,
        method: 'GET',
      })
    },

    apiSpaceContentList: async function (context, { spaceId }) {
      return await context.dispatch('apiRequest', {
        // eslint-disable-next-line camelcase
        endpoint: `/spaces/${spaceId}/content`,
        method: 'GET',
      })
    },

    apiTimezonesList: async function (context) {
      return new Promise((resolve) => {
        context
          .dispatch('apiRequest', {
            endpoint: '/timezones/',
            method: 'GET',
          })
          .then((apiresult) => {
            resolve(apiresult)
          })
      })
    },

    apiTimezonesGet: async function (context, { tz }) {
      return new Promise((resolve) => {
        context
          .dispatch('apiRequest', {
            endpoint: `/timezones/?tz=${tz}`,
            method: 'GET',
          })
          .then((apiresult) => {
            resolve(apiresult)
          })
      })
    },

    apiLoginParseToken: function (context, { token }) {
      const authdata = VueJwtDecode.decode(token)
      context.commit('setAuthState', authdata)
    },

    apiSupportTicketCreate: async function (context, { data }) {
      return new Promise((resolve) => {
        context
          .dispatch('apiRequest', {
            endpoint: '/support/',
            method: 'POST',
            data: {
              ticket: data,
            },
          })
          .then((apiresult) => {
            resolve(apiresult)
          })
      })
    },

    apiGrantRoleMint: async function (context, { ethAddress, isTestNetwork }) {
      return await context.dispatch('apiRequest', {
        endpoint: '/smartcontract/grant_mint_role',
        method: 'POST',
        data: {
          address: ethAddress,
          isTestNetwork: isTestNetwork,
        },
      })
    },

    apiSignMintGet: async function (context, {
      blockchain,
      network,
      contractMethod,
      entityType,
      entityId,
      to,
    }) {
      return await context.dispatch('apiRequest', {
        endpoint: '/blockchains/sign_mint',
        method: 'POST',
        data: {
          blockchain: blockchain,
          network: network,
          method: contractMethod,
          entity_type: entityType,
          entity_id: entityId,
          to: to,
        },
      })
    },

    apiMintToken: async function (context, {
      blockchain,
      network,
      entityType,
      entityId,
      to,
      tokenId,
      salt,
      signature,
    }) {
      return await context.dispatch('apiRequest', {
        endpoint: '/blockchains/mint_token',
        method: 'POST',
        data: {
          blockchain: blockchain,
          network: network,
          entity_type: entityType,
          entity_id: entityId,
          to: to,
          token_id: tokenId,
          salt: salt,
          signature: signature,
        },
      })
    },

    apiConfirmMint: async function (context, { tokenId }) {
      return await context.dispatch('apiRequest', {
        endpoint: '/blockchains/confirm_mint',
        method: 'POST',
        data: {
          token_id: tokenId,
        },
      })
    },

    apiBlockchainsGet: async function (context) {
      return await context.dispatch('apiRequest', {
        endpoint: '/blockchains',
        method: 'GET',
      })
    },

    apiConvertFbx: async function (context, { filename }) {
      return await context.dispatch('apiRequest', {
        endpoint: '/utils/convert',
        method: 'POST',
        data: {
          filename,
        },
      })
    },

    apiCustomDomainsCreate: async function (context, { data }) {
      return await context.dispatch('apiRequest', {
        endpoint: '/custom_domains/',
        method: 'POST',
        data: {
          domain_name: data.id,
          link: data.link,
        },
      })
    },

    apiCustomDomainsGetByLink: async function (context, { linkType, linkId }) {
      return await context.dispatch('apiRequest', {
        endpoint: `/custom_domains/${linkType}/${linkId}`,
        method: 'GET',
      })
    },

    apiCustomDomainsUpdate: async function (context, { data }) {
      return await context.dispatch('apiRequest', {
        endpoint: '/custom_domains/',
        method: 'PUT',
        data: {
          id: data.id,
          custom_domain: data,
        },
      })
    },

    apiCustomDomainsList: async function (context, state) {
      return await context.dispatch('apiRequest', {
        endpoint: `/custom_domains/by_state/${state}`,
        method: 'GET',
      })
    },

    apiPaymentCreateSetupIntent: async function (context) {
      return await context.dispatch('apiRequest', {
        endpoint: '/billing/payments/intent',
        method: 'POST',
        data: {
          payment_processor_id: 'stripe',
        },
      })
    },

    apiPaymentMethodGet: async function (context) {
      return await context.dispatch('apiRequest', {
        endpoint: '/billing/payments/methods',
        method: 'POST',
        data: {
          payment_processor_id: 'stripe',
        },
      })
    },

    apiBillingDetailsSet: async function (context, { data }) {
      return await context.dispatch('apiRequest', {
        endpoint: '/billing/accounts',
        method: 'PUT',
        data: data,
      })
    },

    apiBillingDetailsGet: async function (context) {
      return await context.dispatch('apiRequest', {
        endpoint: '/billing/accounts',
        method: 'GET',
      })
    },

    apiUserSubscriptionInfoGet: async function (context) {
      return await context.dispatch('apiRequest', {
        endpoint: '/billing/subscription',
        method: 'GET',
      })
    },

    apiUserSubscriptionSet: async function (context, { subscriptionId, billingPeriod }) {
      return await context.dispatch('apiRequest', {
        endpoint: '/billing/subscription',
        method: 'PUT',
        data: {
          billing_plan_id: subscriptionId,
          billing_period: billingPeriod,
        },
      })
    },

    apiInvoicesList: async function (context) {
      return await context.dispatch('apiRequest', {
        endpoint: '/billing/invoices',
        method: 'GET',
      })
    },

    apiBillingFeaturesList: async function (context) {
      return await context.dispatch('apiRequest', {
        endpoint: '/billing/features',
        method: 'GET',
      })
    },

    apiBillingPlansList: async function (context) {
      return await context.dispatch('apiRequest', {
        endpoint: '/billing/plans',
        method: 'GET',
      })
    },

    changeSelectedTab: function (context, selectedTab) {
      context.commit('setSelectedTab', selectedTab)
    },

    changeNftStudioState: function (context, nftStudioState) {
      context.commit('setNftStudioState', nftStudioState)
    },

    setTitle: function (context, title) {
      context.commit('setTitle', title)
    },

    apiSpaceAvatarsList: async function (context, { spaceId }) {
      return await context.dispatch('apiRequest', {
        endpoint: `/spaces/${spaceId}/avatars`,
        method: 'GET',
      })
    },

    apiSpaceAvatarsCreate: async function (context, { spaceRef, contentItemRef }) {
      return await context.dispatch('apiRequest', {
        endpoint: '/spaceavatars',
        method: 'POST',
        data: {
          space_avatar: {
            space: spaceRef,
            content_item: contentItemRef,
          },
        },
      })
    },

    apiSpaceAvatarsDelete: async function (context, { spaceAvatarId }) {
      return await context.dispatch('apiRequest', {
        endpoint: `/spaceavatars/${spaceAvatarId}`,
        method: 'DELETE',
        data: {
          id: spaceAvatarId,
        },
      })
    },

    apiPlatformAvatarsList: async function (context) {
      return await context.dispatch('apiRequest', {
        endpoint: '/platform/avatars/',
        method: 'GET',
      })
    },

    apiPlatformAvatarsCreate: async function (context, { contentItemRef }) {
      return await context.dispatch('apiRequest', {
        endpoint: '/platform/avatars/',
        method: 'POST',
        data: {
          platform_avatar: {
            content_item: contentItemRef,
          },
        },
      })
    },

    apiPlatformAvatarsDelete: async function (context, { platformAvatarId }) {
      return await context.dispatch('apiRequest', {
        endpoint: `/platform/avatars/${platformAvatarId}`,
        method: 'DELETE',
        data: {
          id: platformAvatarId,
        },
      })
    },

    apiAnalyticsSpacesGet: async function (context, { id, interval }) {
      return await context.dispatch('apiRequest', {
        endpoint: `/spaces/${id}/analytics?grouping=${interval}`,
        method: 'GET',
      })
    },

    apiAnalyticsProjectsGet: async function (context, { id, interval }) {
      return await context.dispatch('apiRequest', {
        endpoint: `/projects/${id}/analytics?grouping=${interval}`,
        method: 'GET',
      })
    },

    apiCollectionListDataURLGet: async function(context, { tokenUrl }) {
      /* const testURL = 'https://opensea.io/collection/post-photographic-perspectives-taming-the-machine' */
      return await context.dispatch('apiRequest', {
        endpoint: '/blockchains/nftcollection/parse',
        method: 'POST',
        data: {
          url: tokenUrl,
        },
      })
    },

    apiCollectionListDataContractGet: async function(context, { contract }) {
      /* {
       *             "contract": {
       *                 "blockchain": {
       *                   "id": "ETHEREUM",
       *                   "name": "ETHEREUM"
       *                 },
       *                 "network_name": "mainnet",
       *                 "address": "0x5c8cb9d24348c0b4eda49992cb68dae050217d92"
       *               }
       *           } */
      // const testContractAddress = '0xfe4e82e80d1e70c6b60ede19a8bf0b63f5c1b4f3'
      return await context.dispatch('apiRequest', {
        endpoint: '/blockchains/nftcontract/parse',
        method: 'POST',
        data: {
          contract: contract,
        },
      })
    },

    apiNFTCollectionMetadataGet: async function(context, { blockchainId, networkName, address }) {
      return await context.dispatch('apiRequest', {
        endpoint: `/blockchains/nftcollection/meta?blockchain_id=${blockchainId}&network_name=${networkName}&address=${address}`,
        method: 'GET',
      })
    },
  },
  mutations: {
    setTableProp: function (state, [tableName, tablePropName, tablePropValue]) {
      if (!state.table[tableName]) state.table[tableName] = {}
      state.table[tableName][tablePropName] = tablePropValue
    },
    setView: function (state, [viewName, viewValue]) {
      state.view[viewName] = viewValue
    },
    setAuthState: function (state, authData) {
      if (!authData) {
        state.authToken = null
      }

      if (authData === false && router.currentRoute.value.name !== 'Login') {
        state.wasAuthError = true
        state.auth = authData

        router.push({
          name: 'Login',
          query: router.currentRoute.value.name !== 'UsersAccount'
            ? { from: router.currentRoute.value.fullPath }
            : {},
        })
      } else {
        state.auth = authData
        state.wasAuthError = false
      }
    },
    setNftStudioState: function (state, nftStudioState) {
      if (nftStudioState.networkType !== undefined) {
        state.nftStudio.networkType = nftStudioState.networkType
      }
      if (nftStudioState.networkAddress !== undefined) {
        state.nftStudio.networkAddress = nftStudioState.networkAddress
      }
      if (nftStudioState.tokenStatus !== undefined) {
        state.nftStudio.tokenStatus = nftStudioState.tokenStatus
      }
      if (nftStudioState.isTokenOwner !== undefined) {
        state.nftStudio.isTokenOwner = nftStudioState.isTokenOwner
      }
      if (nftStudioState.isNftStudioLoading !== undefined) {
        state.nftStudio.isNftStudioLoading = nftStudioState.isNftStudioLoading
      }
    },
    setSelectedTab: function (state, selectedTab) {
      state.clientState.selectedTabName = selectedTab
    },
    setTitle: function (state, title) {
      const DEFAULT_TITLE = 'W3rlds CMS'
      document.title = title ? `${title} | ${DEFAULT_TITLE}` : DEFAULT_TITLE
    },
  },
  modules: {},
  plugins: [createPersistedState()],
})
